export class CategoriesAction {}

import { createAction, props } from '@ngrx/store';
import { ICategoryFolderInstance } from 'src/app/shared/models/folder.model';

export const invokeCategoriesAPI = createAction(
  '[Categories API] Invoke Categories Fetch API',
  props<{ compositionId: string, force?: boolean }>(),
);

export const categoriesFetchAPISuccess = createAction(
  '[Categories API] Fetch API Success',
  props<{ allCategories: any[] }>(),
);

export const invokeSaveNewCategoryAPI = createAction(
  '[Category API] Invoke save new Category api',
  props<{ newCategory: ICategoryFolderInstance }>(),
);

export const saveNewCategoryAPISucess = createAction(
  '[Category API] save new Category api success',
  props<{ newCategory: any }>(),
);

export const invokeUpdateItemAPI = createAction(
  '[Categories API] Invoke update Item api',
  props<{ updateItem: any }>(),
);

export const updateItemAPISucess = createAction(
  '[Categories API] Update  Item api success',
  props<{ updateItem: any }>(),
);

export const invokeDeleteFolderAPI = createAction(
  '[Categories API] Invoke Delete Folder api',
  props<{ compositionId: any }>(),
);

export const deleteFolderAPISucess = createAction(
  '[Categories API] Delete Folder api success',
  props<{ compositionId: any }>(),
);

export const deleteFolderAPIFailed = createAction(
  '[Categories API] Delete Folder api failed',
  props<{ compositionId: any }>(),
);

export const invokeBulkDeleteFolderAPI = createAction(
  '[Categories API] Invoke Bulk Delete Folder api',
  props<{ compositionIds: any[] }>(),
);

export const bulkDeleteFolderAPISucess = createAction(
  '[Categories API] Bulk Delete Folder api success',
  props<{ compositionIds: any[], status: boolean }>(),
);

export const invokeFolderMoveToConsole = createAction(
  '[Categories API] Move  Item to New Folder api success',
  props<{ newFolderId: any; dropped_item: any; oldFolderId: any }>(),
);

export const invokeBulkFolderMoveToConsoleSuccess = createAction(
  '[Categories API] Bulk Move  Item to New Folder api success',
  props<{ dropped_items: any }>(),
);
