<div class="container py-4 border-top border-light border-2 main-footer">
  <div class="row">
    <div class="col-md-3 my-2">
      <p>
        <a class="navbar-brand" routerLink="/">
          <img
            width="280"
            src="./assets/boomcosole-wide-logo.png"
            alt="logo"
            class="img-fluid" />
        </a>
      </p>

      <p>
        BoomConsole is a web management tool that helps you to save and organize
        your online contents, chats, contacts with folders and descriptions.
      </p>
      <div class="social-icons">
        <a
          href="https://www.linkedin.com/company/boomconsole/"
          target="_blank"
          class="icon-linkedin"
          aria-label="Follow Boomconsole on LinkedIn">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
              d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
          </svg>
          <span class="visually-hidden">Follow Boomconsole on LinkedIn</span>
        </a>
        <a
          href="https://www.youtube.com/@BoomConsole"
          target="_blank"
          class="icon-youtube"
          aria-label="Follow Boomconsole on Youtube">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
              d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z" />
          </svg>
          <span class="visually-hidden">Follow Boomconsole on Youtube</span>
        </a>
      </div>
    </div>
    <div class="col-md-3 my-2">
      <h5>Company</h5>
      <nav>
        <a href="" routerLink="/become-agent">Become an Agent</a>
        <a [routerLink]="isLoggedIn ? ['/subscription'] : ['/plans']"
          >Subscription Plans
        </a>
        <a href="" routerLink="/video-tutorial">Watch Videos</a>
      </nav>
    </div>
    <div class="col-md-3 my-2">
      <h5>Features</h5>
      <nav>
        <a routerLink="/explore/aichats">Bank AI Chats </a>
        <a routerLink="/explore/contacts">Bank Contacts </a>
        <a routerLink="/explore/insights">Bank Insights </a>
        <a routerLink="/explore/web-journey">Secure Web Journey</a>
        <a routerLink="/explore/organize-data">Organize Data</a>
        <a routerLink="/explore/mark-youtube">Mark Youtube Videos </a>
      </nav>
    </div>
    <div class="col-md-3 my-2">
      <h5>Get Started</h5>
      <nav>
        <a [routerLink]="['/signup']">Sign up for free</a>
        <a routerLink="/login">Login</a>
        <a
          target="_blank"
          href="https://chrome.google.com/webstore/detail/boomconsole/gpmcahkaejaehjhalogpakgcnoholepc"
          >Download Extension</a
        >
      </nav>
    </div>
  </div>
  <div class="row mb-0">
    <div class="col-md-8">
      <p class="my-1">
        &copy;
        <a target="_blank" href="https://conceptschema.com/"
          >Freeschema&#8482;</a
        >
        . All rights reserved.
      </p>
    </div>
    <div class="col-md-4 text-md-end text-sm-start">
      <div class="powered-by my-1">
        <a routerLink="/terms-and-conditions" target="_blank">
          Terms & conditions
        </a>
        | <a routerLink="/privacy-policy" target="_blank"> Privacy Policies </a>
      </div>
    </div>
  </div>
</div>
