import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import {
  linkerConsoleFolder,
  linkerMyConsole,
  linkerWriteAccess,
} from '../shared/constants/linkers.const';
import { consoleDefaultFolderLists } from '../shared/constants/defaultFolders.const';
import {
  Connection,
  GetCompositionFromConnectionsWithIndex,
  GetConnectionBulk,
  // RecursiveSearchApi,
  RecursiveSearchApiRaw,
} from 'mftsccs-browser';

const baseUrl = environment.baseUrl;
const aiUrl = environment.aiUrl;
const nodeUrl = environment.nodeUrl;

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  constructor(
    private http: HttpClient,
    private _auth: AuthService
  ) {}

  getRecursiveSearchData(
    composition: number,
    listLinkers: string[],
    textSearch: string
  ): Observable<any> {
    return defer(async () => {
      const tooldata = await RecursiveSearchApiRaw(
        composition,
        listLinkers,
        textSearch
      );
      const myCompositions: number[] = tooldata.compositionIds;
      const concepts = await GetCompositionFromConnectionsWithIndex(
        tooldata.compositionIds,
        tooldata.internalConnections
      );
      const externalConnections = tooldata.externalConnections;
      const external = await GetConnectionBulk(externalConnections);
      const allData: any = [];
      for (let i = 0; i < myCompositions.length; i++) {
        const compId = myCompositions[i];
        if (compId) {
          const mydata: any = {};
          const parent: any = [];
          const visited: number[] = [];
          mydata['id'] = compId;
          mydata['data'] = concepts[compId];
          this.getParentData(external, compId, concepts, parent, visited);
          mydata['parent'] = parent;
          allData[i] = mydata;
        }
      }
      return allData;
    });
  }

  getParentData(
    connections: Connection[],
    conceptId: number,
    concepts: any,
    parent: any,
    visited: number[]
  ) {
    for (let i = 0; i < connections.length; i++) {
      const con = connections[i];
      if (conceptId == con.toTheConceptId) {
        if (concepts[con.ofTheConceptId]) {
          if (!visited.includes(con.id)) {
            const mydata: any = {};
            mydata['id'] = con.ofTheConceptId;
            mydata['data'] = concepts[con.ofTheConceptId];
            parent.push(mydata);
            visited.push(con.id);
            this.getParentData(
              connections,
              con.ofTheConceptId,
              concepts,
              parent,
              visited
            );
          }
        }
      }
    }
  }

  getOwner(): Observable<any> {
    return this.http.get(baseUrl + '/get-owner');
  }

  getAll(itemName: string, pageNumer = 1, inpage = 1000): Observable<any> {
    return this.http.get(
      baseUrl +
        `/concepts/list?type=${itemName}&page=${pageNumer}&inpage=${inpage}`
    );
  }

  getAllWithDataId(
    itemName: string,
    pageNumer = 1,
    inpage = 1000
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        `/list-api-with-data-id?type=${itemName}&page=${pageNumer}&inpage=${inpage}`
    );
  }
  getAllComment(pageNumer = 1, size = 10) {
    return this.http.get(
      `${aiUrl}/suggest_with_link_count?composition_type_id=100203001&page=${pageNumer}&inpage=${size}`
      // `${aiUrl}/suggest_with_link_count?composition_type_id=100149389&page=${pageNumer}&inpage=${size}`
    );
  }
  // https://devai.freeschema.com/api/suggest_with_link_count?composition_type_id=100149389&page=1&inpage=10

  getAllWithUserId(itemName: string, pageNumer = 1, inpage = 1000) {
    return this.http.get(
      baseUrl +
        `/list-api-without-data-id?type=${itemName}&page=${pageNumer}&inpage=${inpage}`
    );
  }

  getAllWithUserData(itemName: string, pageNumer = 1, size = 1000) {
    return this.http.get(
      baseUrl +
        `/list-with-api-data-user-id?type=${itemName}&page=${pageNumer}&inpage=${size}`
    );
  }
  // Get all compositions created only by current user.
  getAllByCurrentuser(itemName: string, pageNumer = 1, size = 1000) {
    return this.http.get(
      baseUrl +
        `/get-all-compositions-with-data-id?composition=${itemName}&page=${pageNumer}&inpage=${size}`
    );
  }

  // Get all compositions created only by given user.
  getAllByProvideduser(
    userCompositionId: any,
    itemName: string,
    pageNumer = 1,
    size = 1000
  ) {
    return this.http.get(
      baseUrl +
        `/get-all-shared-compositions-with-data-id?userCompositionId=${userCompositionId}&composition=${itemName}&page=${pageNumer}&inpage=${size}`
    );
  }

  append(data: any, itemName?: string): Observable<any> {
    console.log('itemName', itemName);
    return this.http.post(baseUrl + `/append`, data);
  }

  appendv2(data: any, itemName?: string): Observable<any> {
    console.log('itemName', itemName);
    return this.http.post(baseUrl + `/append-with-data-id`, data);
  }

  getById(id: number): Observable<any> {
    return this.http.post(baseUrl + `/concepts/${id}`, {});
  }

  getByIdClean(id: number): Observable<any> {
    return this.http.get(baseUrl + `/edit-api-clean?id=${id}`);
  }

  create(data: any) {
    return this.http.post(baseUrl + `/concepts`, data);
  }

  bulkCreate(data: any) {
    return this.http.post(baseUrl + `/concepts/bulk`, data);
  }

  customerAdd(data: any) {
    return this.http.post(baseUrl + `/Stripe/customer/add`, data);
  }

  noAuthCreate(data: any, itemName?: string) {
    console.log('itemName', itemName);
    return this.http.post(baseUrl + '/createApiWithoutAuth', data);
  }
  // Save method as noAuthCreate. Lets eliminate this if possible.
  withoutAuthCreate(data: any) {
    return this.http.post(baseUrl + '/createApiWithoutAuth', data);
  }

  update(data: any): Observable<any> {
    return this.http.post(baseUrl + `/concepts/update`, data);
  }

  updatev2(data: any): Observable<any> {
    return this.http.post(baseUrl + `/updatev2`, data);
  }

  bulkUpdatev2(data: any[]): Observable<any> {
    return this.http.post(baseUrl + `/bulk/updatev2`, data);
  }

  //Patch having clean composition on response.
  patch(data: any): Observable<any> {
    return this.http.post(baseUrl + `/patch/composition`, data);
  }
  //Patch having data composition on response.
  patchv2(data: any): Observable<any> {
    return this.http.post(baseUrl + `/patch/composition-with-data-id`, data);
  }

  bulkPatch(data: any): Observable<any> {
    return this.http.post(baseUrl + `/patch/composition-bulk`, data);
  }

  uploadImage(data: any): Observable<any> {
    return this.http.post(baseUrl + `/upload/images`, data);
  }

  uploadImg(data: any): Observable<any> {
    return this.http.post(
      'https://apitest.boomconcole.com/api/upload/images',
      data
    );
  }

  uploadFile(data: any): Observable<any> {
    return this.http.post(baseUrl + `/Image/UploadFile`, data);
  }

  uploadNewImage(data: any): Observable<any> {
    return this.http.post(baseUrl + `/Image/UploadImage`, data);
  }

  getAllUsers(role = 'ROLE_USER'): Observable<any> {
    return this.http.get(baseUrl + `/get-user-by-roles?role=${role}`);
  }

  getProfile(id: string) {
    return this.http.get(baseUrl + `/edit-api-clean?id=${id}`);
  }

  getAllRoles(): Observable<any> {
    return this.http.get(baseUrl + `/roles/list`);
  }

  // Get all users with access to the composition
  getUserAccessComposition(composition_id: any): Observable<any> {
    return this.http.get(baseUrl + `/get/access?id=${composition_id}`);
  }

  userAccessComposition(data: any): Observable<any> {
    return this.http.post(baseUrl + `/access`, data);
  }

  bulkAccessByConceptId(data: any): Observable<any> {
    return this.http.post(baseUrl + `/bulk/access-by-conceptid`, data);
  }

  removeUserAccessFromCompostion(data: {
    compositionId: number;
    userId: number;
  }): Observable<any> {
    return this.http.post(
      baseUrl +
        `/RemoveUserFromComposition?compositionId=${data.compositionId}&userId=${data.userId}`,
      data
    );
  }

  makeCompositionPublic(
    compositionId: number,
    accessId: number = 5
  ): Observable<any> {
    return this.http.post(
      baseUrl +
        `/updatecompositionaccess?compositionId=${compositionId}&accessId=${accessId}`,
      {}
    );
  }

  createRole(role: any): Observable<any> {
    return this.http.post(baseUrl + `/roles/create`, role);
  }

  addPermission(role: any): Observable<any> {
    return this.http.post(baseUrl + `/permissions/add`, role);
  }

  // search withoutAuth
  searchAPIClean(
    data: any,
    page: number = 1,
    inpage: number = 10
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        `/search-api-clean?composition=${data.compositionName}&search=${data.searchKey}&type=${data?.type}&page=${page}&inpage=${inpage}`
    );
  }

  searchAPICleanWithId(
    data: any,
    page: number = 1,
    inpage: number = 10
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        `/search-api-clean-with-id?composition=${data.compositionName}&search=${data.searchKey}&type=${data?.type}&page=${page}&inpage=${inpage}`
    );
  }

  getAllCategories(categoryType = 'the_category'): Observable<any> {
    return this.http.get(baseUrl + `/searchConcept?type=${categoryType}`);
  }

  searchComposition(
    compositionName: string,
    keyword: string,
    type?: string,
    pageNumer: number = 1,
    inpage: number = 100
  ): Observable<any> {
    if (type) {
      return this.http.get(
        baseUrl +
          `/searchConcept?composition=${compositionName}&search=${keyword}&type=${type}&page=${pageNumer}&inpage=${inpage}`
      );
    } else {
      return this.http.get(
        baseUrl +
          `/searchConcept?composition=${compositionName}&search=${keyword}&page=${pageNumer}&inpage=${inpage}`
      );
    }
  }

  sendEmail(emailData: any): Observable<any> {
    return this.http.post(baseUrl + `/sendmail`, emailData);
  }

  sendEmailWithoutAuth(emailData: any): Observable<any> {
    return this.http.post(baseUrl + `/sendmail-without-auth`, emailData);
  }

  listAPIClean(compositionType: string) {
    return this.http.get(baseUrl + `/list-api-clean?type=${compositionType}`);
  }

  // get User profile details
  getUserProfile(conceptId: number) {
    return this.http.get(baseUrl + `/edit-api-with-data-id?id=${conceptId}`);
  }

  updateUserProfile(profileData: any): Observable<any> {
    return this.http.post(baseUrl + `/updatev2`, profileData);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(baseUrl + `/forgetpassword?email=${email}`, {});
  }

  otpChangePassword(data: any): Observable<any> {
    return this.http.post(baseUrl + `/changepassword`, data);
  }

  getUserReports(data: any): Observable<any> {
    return this.http.post(baseUrl + `/GetReport`, data);
  }

  delete(composition_id: any): Observable<any> {
    return this.http.post(
      baseUrl + `/deleteuser?conceptId=${composition_id}&apiKey=freeschema`,
      {}
    );
  }

  deleteConcept(composition_id: any): Observable<any> {
    return this.http.post(
      baseUrl + `/delete-concept?conceptId=${composition_id}&apiKey=freeschema`,
      {}
    );
  }

  bulkDelete(compositionIdArray: any[]): Observable<any> {
    return this.http.post(
      baseUrl + `/delete-bulk-concept?apiKey=freeschema`,
      compositionIdArray
    );
  }

  // PRODUCTS
  addProduct(productData: any): Observable<any> {
    return this.http.post(baseUrl + `/create/Product`, productData);
  }

  listProducts(): Observable<any> {
    return this.http.post(baseUrl + `/products/list?page=1&inpage=100`, {});
  }

  listProductsWithFilter(product: any): Observable<any> {
    return this.http.post(
      baseUrl +
        `/products/list?type=${product.typeKey}&search=${product.serachKey}`,
      {}
    );
  }

  listProductsWithoutAuth(product: any): Observable<any> {
    return this.http.post(
      baseUrl +
        `/products-without-auth/list?type=${product.typeKey}&search=${product.serachKey}`,
      {}
    );
  }

  getProductDetails(productId: number): Observable<any> {
    return this.http.post(
      baseUrl + `/product/details?productId=${productId}`,
      {}
    );
  }
  getEmailTemplate(templateId: string): Observable<any> {
    return this.http.get(
      baseUrl + `/emailTemplates?templateId=${templateId}`,
      {}
    );
  }
  addProductPrices(priceData: any): Observable<any> {
    return this.http.post(baseUrl + `/products/pricing`, priceData);
  }

  // INVENTORY
  addInventory(data: any): Observable<any> {
    return this.http.post(
      baseUrl +
        `/inventory/add?productId=${data.productId}&quantity=${data.quantity}&price=${data.price}`,
      {}
    );
  }

  listInventory(): Observable<any> {
    return this.http.post(baseUrl + `/inventory/list`, {});
  }

  // agent
  addAgent(data: any): Observable<any> {
    return this.http.post(
      baseUrl +
        `/update/productaccess?productId=${data.productId}&newAgent=${data.agentConceptId}&typeOfAgent=${data.agentType}`,
      {}
    );
  }

  getAgent(productId: any): Observable<any> {
    return this.http.get(
      baseUrl + `/list/productaccess?productId=${productId}`
    );
  }

  // after product sale payment
  buyProduct(product: any): Observable<any> {
    return this.http.post(
      baseUrl +
        `/product/buy?productId=${product.id}&quantity=${product.quantity}&price=${product.price}&paymentToken=${product.paymentToken}`,
      {}
    );
  }
  //get user count how many user signup using affiliate code according to date
  getUserCount(affiliateId: any) {
    return this.http.get(
      baseUrl + `/get-marketing-stats-code?affiliate_code_id=${affiliateId}`
    );
  }

  // check for the payment/bought the product
  checkBoughtProducts(): Observable<any> {
    return this.http.get(
      baseUrl + `/list-left-join?type=internal_sales_item&joiner=paymentId`
    );
  }

  paymentAdd(data: any) {
    return this.http.post(baseUrl + `/Stripe/payment/add`, data);
  }

  paymentAddNew(data: any) {
    return this.http.post(baseUrl + `/Stripe/payment/add-new`, data);
  }

  createCheckoutSession(data: any) {
    return this.http.post(
      baseUrl + `/Stripe/payment/create-checkout-session`,
      data
    );
  }

  bulkNotification(data: any) {
    return this.http.post(baseUrl + `/notification-bulk/send`, data);
  }

  showAllNotification() {
    return this.http.get(baseUrl + `/notification/list?showAll=true`);
  }

  // Groups
  getGroupsFeed(pageNumber = 1, inpage = 10): Observable<any> {
    return this.http.get(
      aiUrl + `/get_group_feed_with_query?page=${pageNumber}&inpage=${inpage}`
    );
  }

  /*************************************************************
   * Semantic (LINKER)
   *************************************************************/
  createSemanticConnection(data: any) {
    return this.http.post(
      baseUrl +
        `/Connection/create-link?composition1=${data.compositionId1}&composition2=${data.compositionId2}&linker=${data.linker}&both=true`,
      data
    );
  }

  createSemanticConnectionWithoutAuth(data: any) {
    return this.http.post(
      baseUrl +
        `/create-link-without-auth?composition1=${data.compositionId1}&composition2=${data.compositionId2}&linker=${data.linker}&both=true`,
      data
    );
  }

  // backward = compositionToJoin -> linker_s -> compositionJsons
  // forward = compositionJsons -> linker_by -> compositionToJoin
  createCompositionAndLinker(data: any): Observable<any> {
    return this.http.post(baseUrl + '/concept-connection', data);
  }

  createCompositionAndLinkerWithoutAuth(data: any): Observable<any> {
    return this.http.post(baseUrl + '/createFolders', data);
  }

  getSemanticConnection(
    data: any,
    pageNumer: number = 1,
    pageSize: number = 1000
  ) {
    return this.http.get(
      baseUrl +
        `/Connection/get-link?compositionId=${data.compositionId}&linker=${data.linker}&page=${pageNumer}&inpage=${pageSize}`
    );
  }

  getSemanticConnectionWithDataId(
    data: any,
    pageNumber: number = 1,
    inPage: number = 10
  ) {
    return this.http.get(
      baseUrl +
        `/Connection/get-link-data-id?compositionId=${data.compositionId}&linker=${data.linker}&page=${pageNumber}&inpage=${inPage}`
    );
  }

  setSemanticConnection(data: any) {
    return this.http.post(
      baseUrl +
        `/Connection/get-link-secondary?compositionId=${data.compositionId}&linker=${data.linkFrom}&secondaryLinker=${data.linkTo}`,
      data
    );
  }

  removeSemanticConnection(data: any) {
    return this.http.post(
      baseUrl +
        `/Connection/delete-link?composition1=${data.compositionId1}&composition2=${data.compositionId2}&linker=${data.linker}&both=true`,
      data
    );
  }

  bulkRemoveSemanticConnection(data: any) {
    return this.http.post(baseUrl + `/Connection/delete-link-bulk`, data);
  }

  bulkSemanticBackwardConnection(formData: {
    compositionId: number;
    linkers: any;
    both: boolean;
    toConnections: Array<number>;
    forward: boolean;
  }) {
    return this.http.post(baseUrl + `/Connection/create-link-array`, formData);
  }

  createBulkSemanticConnection(formData: {
    compositionId: number;
    linker: string;
    both: boolean;
    toConnections: Array<number>;
  }) {
    return this.http.post(baseUrl + `/Connection/create-link-array`, formData);
  }

  createBulkLinkersSemanticConnection(formData: {
    compositionId: any;
    linkers: Array<any>;
    both: boolean;
    toConnections: Array<any>;
  }) {
    return this.http.post(baseUrl + `/Connection/create-link-array`, formData);
  }

  // Connection (linker)
  createComponentLink(
    compositionId1: number,
    compositionId2: number,
    linker: string,
    both: boolean = true,
    count: boolean = true
  ) {
    return this.http.post(
      baseUrl +
        `/Connection/create-link?composition1=${compositionId1}&composition2=${compositionId2}&linker=${linker}&both=${both}&count=${count}`,
      {}
    );
  }

  getMarketingStats(
    userConceptId: number,
    fromDate: string,
    toDate: string
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        `/get-marketing-stats?userConceptId=${userConceptId}&fromDate=${fromDate}&toDate=${toDate}`
    );
  }
  getStats(userConceptId: number): Observable<any> {
    return this.http.get(
      baseUrl + `/get-marketing-stats?userConceptId=${userConceptId}`
    );
  }
  getMarketingStatsCode(
    affiliateCodeId: string,
    fromDate: string,
    toDate: string
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        `/get-marketing-stats-code?affiliate_code_id=${affiliateCodeId}&fromDate=${fromDate}&toDate=${toDate}`
    );
  }
  deleteComponentLink(
    compositionId1: number,
    compositionId2: number,
    linker: string,
    both: boolean = true
  ) {
    return this.http.post(
      baseUrl +
        `/Connection/delete-link?composition1=${compositionId1}&composition2=${compositionId2}&linker=${linker}&both=${both}`,
      {}
    );
  }

  getLink(
    compositionId: number | string,
    linker: string,
    pageNumer = 1,
    inpage = 100
  ) {
    return this.http.get(
      baseUrl +
        `/Connection/get-link?compositionId=${compositionId}&linker=${linker}&page=${pageNumer}&inpage=${inpage}`
    );
  }

  getSecondaryLinkedComposition(
    compositionId: number,
    primaryLinker: string,
    secondaryLinker: string,
    pageNumer = 1,
    inpage = 10
  ) {
    return this.http.post(
      baseUrl +
        `/Connection/get-link-secondary?compositionId=${compositionId}&linker=${primaryLinker}&secondaryLinker=${secondaryLinker}&page=${pageNumer}&inpage=${inpage}`,
      {}
    );
  }

  listAllWithCount(data: any, inpage: number = 100, page: number = 1) {
    return this.http.post(
      baseUrl + `/list-all?inpage=${inpage}&page=${page}`,
      data
    );
  }

  listAllOfUser(
    data: any,
    ownerComposionId: any,
    inpage: number = 100,
    page: number = 1
  ) {
    return this.http.post(
      baseUrl +
        `/list-all-shared?conceptOwner=${ownerComposionId}&inpage=${inpage}&page=${page}`,
      data
    );
  }

  listAllPublicSharedPosts(
    data: any,
    ownerComposionId: any,
    inpage: number = 100,
    page: number = 1
  ) {
    return this.http.post(
      baseUrl +
        `/all-user-shared-and-public-data?conceptOwner=${ownerComposionId}&inpage=${inpage}&page=${page}`,
      data
    );
  }

  searchLinkMultiple(data: any) {
    return this.http.post(baseUrl + `/Connection/search-link-multiple`, data);
  }

  searchLinkMultipleAll(data: any) {
    return this.http.post(
      baseUrl + `/Connection/search-link-multiple-all`,
      data
    );
  }

  searchLinkMultipleClean(data: any) {
    return this.http.post(
      baseUrl + `/Connection/search-link-multiple-clean`,
      data
    );
  }

  listAllWithoutAuthWithLinker(data: any, inpage = 100, page = 1) {
    return this.http.post(
      baseUrl +
        `/list-all-without-auth-with-linker?inpage=${inpage}&page=${page}`,
      data
    );
  }

  listUserComposition(data: object) {
    return this.http.post(baseUrl + `/list-all-of-user`, data);
  }

  recursiveSearchNestedLinks(data: any) {
    return this.http.post(baseUrl + `/Connection/recursivesearch`, data);
  }

  recursiveSearchNestedLinksWithoutAuth(data: any) {
    return this.http.post(baseUrl + `/without-auth-recursivesearch`, data);
  }
  /*************************************************************
   * Create Boomconsole Folders After Signup
   *************************************************************/
  createDefaultFolders(user_id: any) {
    const data = {
      compositionJsons: consoleDefaultFolderLists,
      direction: 'backward',
      linkers: [linkerConsoleFolder, linkerMyConsole],
      both: true,
      count: true,
      userId: user_id,
    };
    return this.http.post(baseUrl + `/createFolders`, data);
  }
  /************************************************
   * Create Composition with connection.
   * {
   * "compositionJsons": [{
   *   "test": {
   *        "ram" :"tom"
   *    }
   *  },
   * {
   *    "gwdgs": {
   *        "sadfasdf": "fah"
   *   }
   * }],
   * "compositionToJoin": 125125,
   * "direction": "backward",
   * "linker": "linker",
   * "both": true,
   * "count": true
   * }
   * *********************************** */
  createConceptWithConnection(data: any) {
    return this.http.post(baseUrl + `/api/concept-connection`, data);
  }

  hasWriteAccessLink(
    conceptId1: any,
    conceptId2: any,
    linker: string = linkerWriteAccess
  ) {
    return this.http.get(
      baseUrl +
        `/api/is_connected?conceptId1=${conceptId1}&conceptId2=${conceptId2}&linker=${linker}`
    );
  }

  createCoupon(data: any) {
    // https://devboom.freeschema.com/api/Stripe/payment/create-coupoun
    // https://devboom.freeschema.com/api
    return this.http.post(baseUrl + `/Stripe/payment/create-coupoun`, data);
  }
  getMySubscription(): Observable<any> {
    return this.http.post(baseUrl + `/get-my-subscription`, {});
  }

  updateMySubscription(data: any): Observable<any> {
    return this.http.post(
      baseUrl + `/Stripe/payment/update-subscription`,
      data
    );
  }

  cancelMySubscription(data: any): Observable<any> {
    return this.http.post(
      baseUrl + `/Stripe/payment/cancel-subscription`,
      data
    );
  }

  directDiscount(data: any): Observable<any> {
    return this.http.post(baseUrl + `/direct-discount`, data);
  }

  // session
  createSessionId(): Observable<any> {
    return this.http.get(baseUrl + `/create-session-id`);
  }

  // Statistics
  getUserSessionStats(userConcept: number): Observable<any> {
    return this.http.get(
      baseUrl + `/get-user-session-stats?userConceptId=${userConcept}`
    );
  }

  getSignupByDate(fromDate: string, toDate: string): Observable<any> {
    return this.http.get(
      baseUrl + `/get-signup-by-date?fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  getUserStats(page: number = 1, inpage: number = 10): Observable<any> {
    return this.http.get(
      baseUrl + `/get-user-stats?page=${page}&inpage=${inpage}`
    );
  }

  getIpVisistsByDate(fromDate: string, toDate: string): Observable<any> {
    return this.http.get(
      baseUrl + `/get-ip-visits-by-date?fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  getTopUserByDate(
    fromDate: string,
    toDate: string,
    limit: string
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        `/get-top-users?fromDate=${fromDate}&toDate=${toDate}&limit=${limit}`
    );
  }

  getTotalVisistsByDate(fromDate: string, toDate: string): Observable<any> {
    return this.http.get(
      baseUrl +
        `/get-total-visits-by-date?fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  getSessionCreationByDate(fromDate: string, toDate: string): Observable<any> {
    return this.http.get(
      baseUrl +
        `/get-session-creation-by-date?fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  getUserBugStatistics(fromDate: string, toDate: string): Observable<any> {
    return this.http.get(
      baseUrl +
        `/-reporting/get-statistics?fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  getUserBugStatisticsByEmail(
    email: string,
    fromDate: string,
    toDate: string
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        `/-reporting/get-statistics?email=${email}&fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  createSurvey(data: any) {
    return this.http.post(nodeUrl + `/survey`, data);
  }

  surveyUninstall(data: any) {
    return this.http.post(nodeUrl + `/survey/uninstall-extension`, data);
  }

  // check entity
  checkEnitity(id: number): Observable<any> {
    return this.http.get(baseUrl + `/get-entity-from-user?userConceptId=${id}`);
  }
  //subuser entity

  getLinkReverse(id: number, linker: string): Observable<any> {
    return this.http.get(
      baseUrl +
        `/Connection/get-link-reverse?compositionId=${id}&linker=${linker}`
    );
  }

  getdataFromImageUrl(url: string): Observable<any> {
    return this.http.get(nodeUrl + `/file-util/image-to-data?image_url=${url}`);
  }
}
