import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-share-access-token',
  template: '',
  styleUrls: ['./share-access-token.component.scss'],
})
export class ShareAccessTokenComponent implements OnInit {
  constructor(private _auth: AuthService) {}
  ngOnInit(): void {
    // window.top.postMessage('hello world', '*')
    const userProfile: any = JSON.stringify(this._auth.loadFromLocalStorage());

    parent.postMessage(userProfile, '*');
    window.addEventListener('message', (message: any) => {
      console.log('message,', message, message?.data ? message.data : {});
      const profileFromMessage: any = message?.data
        ? JSON.parse(message?.data)
        : {};

      if (
        !!profileFromMessage?.token &&
        typeof profileFromMessage?.token != 'undefined'
      ) {
        const profile = JSON?.parse(message.data);
        console.log(profile, 'profile...');

        if (profile?.userConcept) {
          this._auth.saveToLocalStorage(profile);
        } else {
          this._auth.logout();
        }
      } else if (message.data == '{}') {
        this._auth.logout();
      }
    });
  }
}
