export enum captureTypes {
  Document = "Document",
  Image = "Image",
  Chatgpt = "extension_chatgpt",
  TracedUrls = "traced_url",
  ChatgptAll = "extension_chatgpt_all",
  VisualText = "visual_text",
  PlainText = "plain_text",
  PollData = "poll",
  URL = "url",
  OnlyImages = "only_images",
  Screenshot = "screenshot",
  HtmlOnly = "all_but_css",
  ALL = "all",
  FullPage = "full_page",
  GoogleBard = 'extension_googlebard',
  YouChat = 'extension_youchat',
  PoeChat = 'extension_poechat',
  linkedin = 'extension_linkedin',
  monster = 'extension_monster',
  indeed = 'extension_indeed',
  maps = 'extension_maps',
  crm = 'web_crm',
  whatsapp = "extension_whatsapp",
  whatsappChat = "extension_whatsapp_chat",
  youtubeNotes = "youtube_notes",
  amazonItem = "item_amazon",
  ebayItem = "item_ebay",
  costcoItem = "item_costco",
  kayakFlight = "item_kayak",
  expediaFlight = "item_expedia",
  agodaFlight = "item_agoda",
  documentation = "documentation",
}