import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/auth/auth.service';

const nodeUrl = environment.nodeUrl;

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(
    private http: HttpClient,
    private _auth: AuthService
  ) {}

  getAllGroups(): Observable<any> {
    return this.http.get(nodeUrl + '/group/list');
  }
  getRecentMessageAll(
    entityId: any,
    pageNumer = 1,
    inpage = 1000,
  ): Observable<any> {
    return this.http.get(
      nodeUrl +
        `/message/get-recent-instant-message?entityId=${entityId}&page=${pageNumer}&inpage=${inpage}`
    );
  }
  getRecentMessageGroup(
    entityId: any,
    pageNumer = 1,
    inpage = 1000,
    type:any,

  ): Observable<any> {
    return this.http.get(
      nodeUrl +
        `/message/get-recent-instant-message?entityId=${entityId}&page=${pageNumer}&inpage=${inpage}&type=${type}`
    );
  }

  getFeed(entityId: any, pageNumer = 1, inpage = 1000) {
    return this.http.get(
      nodeUrl +
        `/post/list-group-feed?entityId=${entityId}&page=${pageNumer}&inpage=${inpage}&order=desc`
    );
  }

  getGroupById(groupId: any): Observable<any> {
    return this.http.get(nodeUrl + '/group/' + groupId);
  }
  //like and comment
  likePost(postId: any, entityId: any) {
    return this.http.post(nodeUrl + `/post/like/${postId}/${entityId}`, {});
  }
  listComment(postId: any, entityId: any) {
    return this.http.get(nodeUrl + `/post/comment/${postId}/${entityId}`);
  }

  deleteMessage(groupId: any, messageId: any) {
    return this.http.delete(
      nodeUrl + `/message/delete/${groupId}/${messageId}`,
      {}
    );
  }

  deleteDM(groupId: any, messageId: any) {
    return this.http.delete(
      nodeUrl + `/message/delete-dm/${groupId}/${messageId}`,
      {}
    );
  }

  deleteComment(postId: any, commentId: any) {
    return this.http.delete(
      nodeUrl + `/post/comment/delete/${postId}/${commentId}`,
      {}
    );
  }
  updateMessage(
    messageId: number,
    Id: number,
    value: string,
    createdAt: Date
  ): Observable<any> {
    const body = [
      {
        type: 'text',
        id: Id,
        value: value,
        createdAt: createdAt,
      },
    ];
    return this.http.put(nodeUrl + `/message/update/${messageId}`, body);
  }

  updateComment(
    commentId: number,
    textId: number,
    value: string
  ): Observable<any> {
    const body = [
      {
        type: 'text',
        id: textId,
        value: value,
      },
    ];
    return this.http.put(nodeUrl + `/post/update/comment/${commentId}`, body);
  }

  postComment(formData: { postId: number; entityId: number; text: string }) {
    return this.http.post(nodeUrl + `/post/comment`, formData);
  }

  likeComment(commentId: any, entityId: any) {
    return this.http.post(
      nodeUrl + `/post/comment/like/${commentId}/${entityId}`,
      {}
    );
  }

  getGroupFeeds(groupId: any, entityId: any): Observable<any> {
    return this.http.get(nodeUrl + `/post/${groupId}/${entityId}`);
  }

  createGroup(body: any): Observable<any> {
    return this.http.post(nodeUrl + '/group/create/', body);
  }

  joinGroup(body: any): Observable<any> {
    return this.http.post(nodeUrl + '/group/join/', body);
  }

  createPost(body: any, groupId: any): Observable<any> {
    return this.http.post(nodeUrl + `/post/${groupId}`, body);
  }

  getMyGroups(entityId: any): Observable<any> {
    return this.http.get(nodeUrl + '/group/mygroup/' + entityId);
  }

  getMyJoinedGroups(entityId: any): Observable<any> {
    return this.http.get(nodeUrl + '/group/joined/' + entityId);
  }

  viewGroupMembers(groupId: any): Observable<any> {
    return this.http.get(nodeUrl + '/group/members/' + groupId);
  }

  viewGroupMembersAndInvitees(groupId: any): Observable<any> {
    return this.http.get(nodeUrl + '/group/members-contact/' + groupId);
  }

  updateGroupDetails(groupId: any, body: any) {
    return this.http.put(nodeUrl + '/group/update/' + groupId, body);
  }

  deleteGroup(entityId: any, groupId: any) {
    return this.http.delete(nodeUrl + `/group/delete/${entityId}/${groupId}`);
  }

  updatePost(postId: any, body: any) {
    return this.http.put(nodeUrl + '/post/update/' + postId, body);
  }

  leaveGroup(groupId: any, body: any) {
    return this.http.post(nodeUrl + '/group/member/' + groupId, body);
  }

  requestToJoinGroup(body: any) {
    return this.http.post(nodeUrl + '/group/request', body);
  }

  cancelRequest(groupId: any, body: any) {
    return this.http.post(nodeUrl + '/group/cancel-request/' + groupId, body);
  }

  getGroupsAccordingToMessage(entityId: any): Observable<any> {
    return this.http.get(
      nodeUrl +
        `/message/get-latest-group?entityId=${entityId}&page=1&inpage=40&order=asc`
    );
  }

  searchUserFromContactAndBc(keyword = '') {
    return this.http.get(nodeUrl + `/group/search/contact?search=${keyword}`);
  }

  searchGroup(keyword = '') {
    return this.http.post(nodeUrl + `/group/search?name=${keyword}`, {});
  }

  searchGroupDM(entityId: any, groupType: any, keyword = '') {
    return this.http.get(
      nodeUrl +
        `/message/search-groups?entityId=${entityId}&type=${groupType}&search=${keyword}&page=1&inpage=100`
    );
  }

  searchUser(keyword = '') {
    return this.http.get(nodeUrl + `/group/search/user?search=${keyword}`);
  }

  // MESSAGES
  fetchGroupMessages(page: number, groupId: any) {
    return this.http.get(nodeUrl + `/message/${page}/${groupId}`);
  }

  fetchDMs(page: number, groupId: any) {
    return this.http.get(nodeUrl + `/message/dm/${page}/${groupId}`);
  }

  inviteUserToGroup(body: any, groupId: any) {
    return this.http.post(nodeUrl + '/group/invite-member/' + groupId, body);
  }

  listGroupInvites(entityId: any) {
    return this.http.get(nodeUrl + '/group/invitees/' + entityId);
  }

  approveGroupInvite(body: any) {
    return this.http.post(nodeUrl + '/group/approve', body);
  }

  deletePost(groupId: any, postId: any) {
    return this.http.delete(nodeUrl + `/post/delete/${groupId}/${postId}`);
  }

  // DIRECT MESSAGE
  startDM(payload: any) {
    return this.http.post(nodeUrl + '/message/start-dm', payload);
  }

  getMyDMUsers(entityId: any) {
    return this.http.get(nodeUrl + `/message/get-dm-users/${entityId}`);
  }

  getDMUserDetail(privateGroupId: any, entityId: any) {
    return this.http.get(
      nodeUrl + `/message/get-dm-userdetail/${privateGroupId}/${entityId}`
    );
  }
}
