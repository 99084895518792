<div class="modal-backdrop" (click)="modal.dismiss()">
  <div class="modal-content">
    <div class="header">
      <div class="name">
        <div class="chat-msg-profile">
          <img class="chat-msg-img" src="{{ sender?.profileImg }}" alt="" />
        </div>
        <span class="sender-name"
          >{{ sender?.firstName }} {{ sender?.lastName }}</span
        >
      </div>
      <div class="actions">
        <span
          class="material-symbols-outlined"
          title="Download image"
          (click)="downloadImage()"
          >cloud_download</span
        >
        <span
          class="material-symbols-outlined"
          title="Open in new window"
          (click)="openImage()"
          >open_in_new</span
        >
        <span
          class="material-symbols-outlined"
          title="Close"
          (click)="modal.dismiss()"
          >close</span
        >
      </div>
    </div>
    <div class="image-container">
      <img [src]="imageUrl" class="img-fluid" alt="Image Preview" />
    </div>
  </div>
</div>
