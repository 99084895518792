

<div class="modal-header text-center">
<!-- <div class="auth-container"> -->
    <!-- <div class="row m-0 auth-main"> -->
      <!-- <div class="col-sm-6 px-0 d-none d-sm-block left-img-section"> -->
  
        <!-- <div class="left-img"></div> -->
        <!-- <div class="center-logo">
          <a routerLink="/" class="text-center">
            <img src="./assets/boomcosole-logo.png" alt="boomconsole logo" class="img-fluid">
          </a>
        </div>
      </div> -->
      <!-- <div class="col-sm-6 p-5 align-self-center"> -->
        <!-- <div class="auth-form-section"> -->
        <div class="logo text-center">
            <img width="232" src="./assets/boomcosole-wide-logo.png" />
          </div>
          
            
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="_activeModal.dismiss('Cross click')"
            ></button>
          </div>
          <div class="modal-body signupSection">
            <h5 class="modal-title"> <b> Sign in </b> </h5>
          <!-- <div class="login-title">
            <h2>Sign In</h2>
          </div>
          <div class="flex items-baseline mt-0.5">
            <div class="ng-tns-c137-1">
              Don't have an account?
              <a class="ml-1 text-primary-500" routerLink="/signup">Sign up </a>
            </div>
          </div>
          <div *ngIf="loginError" class="validation_error alert alert-danger">
            {{ errorMessage }}
          </div> -->
          <!-- <div class="login-form mt-3"> -->
            <div style="margin-left: -20px;">
              <button #loginRef type="button" class="btn btn-danger btn-google-login">Login with Google</button>
            </div>
            <form id="boom_login_form" class="login-form validate-form" (ngSubmit)="onSubmit()" [formGroup]="loginForm"
              #loginFormId="ngForm">
  
              <div class="form-group my-2 auth-form">
                <label>Email address</label>
                <input type="email" class="form-control" placeholder="Enter email" formControlName="email" />
                <small class="err_msg" *ngIf="
                    email &&
                    email.invalid &&
                    (email.touched || loginFormId.submitted)
                  ">
                  Please enter valid email.
                </small>
              </div>
              <div class="form-group my-2">
                <label>Password</label>
                <input type="password" class="form-control" placeholder="Password" formControlName="password" />
                <small class="err_msg" *ngIf="
                    password &&
                    password.invalid &&
                    (password.touched || loginFormId.submitted)
                  ">
                  Please enter valid password.
                </small>
              </div>
              <div class="d-flex align-items-center justify-content-between my-2">
               
                <a class="ml-1 text-primary-500" routerLink="/forgot-password">Forgot Password?
                </a>
              </div>
              <div class="flex items-baseline mt-0.5">
                <div class="ng-tns-c137-1">
                  Don't have an account?
                  <a class="ml-1 text-primary-500"  (click)="closeModal()" routerLink="/signup">Sign up </a>
                </div>
              </div>
  
              <button *ngIf="notLoading; else loading" type="submit" class="btn btn-primary mt-2">
                Submit
              </button>
              <ng-template #loading>
                <button type="button" [disabled]="true" class="btn btn-primary btn-loading">
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">.</span>
                  </div>
                  Loading...
                </button>
              </ng-template>
              <!-- <div class="flex items-baseline mt-0.5">
                <div class="ng-tns-c137-1">
                  Don't have an account?
                  <a class="ml-1 text-primary-500" routerLink="/signup">Sign up </a>
                </div>
              </div> -->
  
              
            </form>
          </div>
       
    <!-- </div> -->
    <!-- </div> -->

    <!--.auth-main ends-->