import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'UTCToLocalTime',
  standalone: true,
})
export class UTCToLocalTimePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    if (value?.substring(value?.length - 1)?.toLowerCase() == 'z') {
      return new Date(value).toLocaleString();
    }
    if (value?.includes('UTC')) {
      const utcDate = new Date(value);
      return utcDate.toLocaleString();
    }

    const utcDate:any = new Date(`${value} UTC`);
    if (utcDate != 'Invalid Date') {
      return utcDate.toLocaleString();
    }

    return new Date(parseInt(value)).toLocaleString();
  }
}
