import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ViewCopiedLinkComponent } from './modules/captures/view-copied-link/view-copied-link.component';
import { ShareAccessTokenComponent } from './pages/partials/share-access-token/share-access-token.component';
import { NotFoundComponent } from './modules/pages/not-found/not-found.component';
import { GroupChatComponent } from './shared/messages-box/group-chat/group-chat.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'captures/boomgpt/:id',
    component: ViewCopiedLinkComponent,
  },
    { path: 'group-chat/:id',
       component: GroupChatComponent },   
  {
    path: 'kakhagagha1234',
    component: ShareAccessTokenComponent,
  },
  
  {
    path: '',
    loadChildren: () =>
      import('./modules/layout/layout.module').then(m => m.LayoutModule),
  },
  {
    path: 'explore',
    loadChildren: () =>
      import('./modules/explore/explore.module').then(m => m.ExploreModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/auth-module/auth-module.module').then(
        m => m.AuthModuleModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/extension-help/extension-help.module').then(
        m => m.ExtensionHelpModule
      ),
  },
  {
    path: 'in',
    loadChildren: () =>
      import('./modules/landing-page/landing-page.module').then(
        m => m.LandingPageModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'plans',
    loadChildren: () =>
      import('./modules/plans/plans.module').then(m => m.PlansModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'page-not-found',
    component: NotFoundComponent,
  },
  {
    redirectTo: 'page-not-found',
    path: '**',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
