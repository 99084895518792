<div id="home_top" class="home-page">
  <a href="#home_top" class="btn_gototop">
    <span class="material-symbols-outlined"> arrow_upward </span>
  </a>
  <app-main-header></app-main-header>
  <section class="position-relative pb-5">
    <div class="container">
      <div class="hero_image_background">
        <img src="./assets/home-hero-bg.png" />
      </div>
      <app-hero-slider [isLoggedIn]="isLoggedIn"></app-hero-slider>
    </div>
    <div class="hero-cta-section">
      <h6 class="heading_heading6__hJig2">Explore our features</h6>
      <a class="homepage-cta-section_scrollArow" href="#features">
        <span class="material-symbols-outlined"> south </span>
      </a>
    </div>
  </section>

  <section class="py-5 get-started">
    <div class="container my-5">
      <div class="text-center py-3">
        <h2 class="section_heading">Set up in 4 Simple Steps</h2>
      </div>
      <div class="row m-auto my-5">
        <div class="col-md-3 starting-steps">
          <img
            width="75"
            height="79"
            src="./assets/home/chrome-store.svg"
            alt="extension icon"
            class="img-fluid w-25" />
          <h3>Add Browser Extension</h3>
          <p>
            Start by installing BoomConsole directly from the<a
              href="https://chrome.google.com/webstore/detail/boomconsole/gpmcahkaejaehjhalogpakgcnoholepc"
              target="_blank">
              Chrome Web Store</a
            >
            or by clicking "Add to Chrome" on the top of the Home Page.
            BoomConsole works on Chrome Browser, Edge Browser and Brave Browser.
            (Firefox coming soon).
          </p>
        </div>
        <div class="col-md-3 starting-steps">
          <img
            width="75"
            height="79"
            src="./assets/home/3d-icons/3d-register.png"
            alt="zoom icon"
            class="img-fluid w-25" />
          <h3>Register</h3>
          <p class="m-0">
            Sign up as an user-entity by filling up a form in the Signup Page.
            Sign up as:
          </p>
          <ul class="px-3 my-0">
            <li>An Individual</li>
            <li>A Corporation</li>
            <li>A Student</li>
          </ul>
        </div>
        <div class="col-md-3 starting-steps">
          <img
            width="75"
            height="79"
            src="./assets/home/3d-icons/folder-dynamic-color.png"
            alt="zoom icon"
            class="img-fluid w-25" />
          <h3>Save & Organize</h3>
          <p>
            Once signed up, start saving contents by clicking on BoomConsole
            Extension next to Extension Icon on your browser. Describe your
            experiences on the sites you visit and organize your data inside
            folders you remember.
          </p>
        </div>
        <div class="col-md-3 starting-steps">
          <img
            width="75"
            height="79"
            src="./assets/home/3d-icons/locker-dynamic-color.png"
            alt="safe locker icon"
            class="img-fluid w-25" />
          <h3>Access Your Data</h3>
          <p>
            Once you save your data, access them in
            <strong>Your Console</strong>. Simply search and find your
            information whenever you need it, use the sidebar while finding your
            contents outside the BoomConsole browser.
          </p>
        </div>
        <!-- <div class="text-center py-4">
          <button type="button" class="btn btn-success mt-3 mb-4 btn-main-cta" (click)="open(content)">See demo
            video</button>
        </div> -->
      </div>
    </div>
  </section>

  <section id="features" class="section_chatgpt sec">
    <div class="container my-4">
      <div class="row align-items-center row-gap">
        <div class="col-md-6 video_wrapper order-md-1 order-1">
          <app-aichat-graphics-with-icons></app-aichat-graphics-with-icons>
        </div>
        <div class="col-md-6 px-5 order-md-2 order-2">
          <h2 class="section_heading">Bank Your AI Chats</h2>
          <h3 class="section_subheading">
            Chats from Chat GPT, Google Gemini, You Chat and Poe Chat
          </h3>
          <p>
            The BoomConsole browser extension simplifies the organization of AI
            chat conversations. Effortlessly save and secure your AI chats from
            ChatGPT, Google's Gemini, Poe, and You Chat. Organize your chats
            within BoomConsole's directory system and export your chats in Word,
            PDF, JSON, and CSV formats. Edit your chats and refeed the same
            questions to other chatbots to compare responses. Share your chats
            with friends and colleagues, streamlining your chat management for
            storage, retrieval, and analysis.
          </p>
          <p>
            <a
              class="btn btn-featured btn-featured-mini"
              routerLink="/explore/aichats">
              Explore
              <span class="material-symbols-outlined"> chevron_right </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="feature_contacts" class="section_chatgpt sec">
    <div class="container my-4">
      <div class="row align-items-center row-gap">
        <div class="col-md-6 px-5 order-md-1 order-2">
          <h2 class="section_heading">Bank Your Contacts</h2>
          <h3 class="section_subheading">
            Data from LinkedIn, Google maps, Monster and Indeed
          </h3>
          <p>
            Manage your professional network by importing contacts from
            LinkedIn, WhatsApp, Google Maps, Indeed, and Monster. Store phone
            numbers, email addresses, descriptions and locations in your
            personal contact manager. Edit, categorize and add notes to your
            contacts, simplifying management, storage, and retrieval in one
            organized place.
          </p>
          <p>
            <a
              class="btn btn-featured btn-featured-mini"
              routerLink="/explore/contacts">
              Explore
              <span class="material-symbols-outlined"> chevron_right </span>
            </a>
          </p>
        </div>

        <div class="col-md-6 video_wrapper order-md-2 order-1">
          <img width="624" height="560" src="./assets/bj/featured_img_04.png" />
        </div>
      </div>
    </div>
  </section>

  <section id="feature_data" class="section_chatgpt sec">
    <div class="container my-4">
      <div class="row align-items-center row-gap">
        <div class="col-md-6 video_wrapper order-md-1 order-1">
          <img width="624" height="560" src="./assets/bj/featured_img_02.png" />
        </div>
        <div class="col-md-6 px-5 order-md-2 order-2">
          <h2 class="section_heading">Organize your Data</h2>
          <h3 class="section_subheading">
            Consolidate your chats, emails, contacts
          </h3>
          <p>
            Consolidate your chats, emails, contacts, news and more using
            BoomConsole. Create a private, organized directory system to store
            important files, documents, and images. Whether it's meeting notes,
            receipts, manuals, contact addresses, or family recipes, BoomConsole
            keeps your personal and professional life secure and organized.
          </p>
          <p>
            <a
              class="btn btn-featured btn-featured-mini"
              routerLink="/explore/organize-data">
              Explore
              <span class="material-symbols-outlined"> chevron_right </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="feature_screenshot" class="section_chatgpt sec">
    <div class="container my-4">
      <div class="row align-items-center row-gap">
        <div class="col-md-6 px-5 order-md-1 order-2">
          <h2 class="section_heading">Secure Screenshots</h2>
          <h3 class="section_subheading">Take screenshots of your pages</h3>
          <p>
            Secure your web journey by taking easy screenshots of the pages you
            view and add description to your screenshots. Take screenshots of
            the images, articles, news and file them in an organized directory
            system.
          </p>
          <p>
            <a
              class="btn btn-featured btn-featured-mini"
              routerLink="/explore/organize-data">
              Explore
              <span class="material-symbols-outlined"> chevron_right </span>
            </a>
          </p>
        </div>

        <div class="col-md-6 video_wrapper order-md-2 order-1">
          <img width="624" height="560" src="./assets/bj/screenshot.png" />
        </div>
      </div>
    </div>
  </section>

  <section id="feature_youtube" class="section_chatgpt sec">
    <div class="container my-4">
      <div class="row align-items-center row-gap">
        <div class="col-md-6 video_wrapper order-md-1 order-1">
          <img width="624" height="560" src="./assets/bj/youtube-button.png" />
        </div>
        <div class="col-md-6 px-5 order-md-2 order-2">
          <h2 class="section_heading">Mark Youtube Videos</h2>
          <h3 class="section_subheading">Mark timelines in Youtube videos</h3>
          <p>
            Mark specific timelines in YouTube videos with notes on the timeline
            for search and review. BoomConsole allows you to annotate key
            moments in your videos, adding notes directly to the timestamps
            where important information or scenes occur. Organize these
            annotated timestamps within your personalized filing system, making
            it simple to access and reference your marked content.
          </p>
          <p>
            <a
              class="btn btn-featured btn-featured-mini"
              routerLink="/explore/mark-youtube">
              Explore
              <span class="material-symbols-outlined"> chevron_right </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="feature_insights" class="section_chatgpt sec">
    <div class="container my-4">
      <div class="row align-items-center row-gap">
        <div class="col-md-6 px-5 order-md-1 order-2">
          <h2 class="section_heading">Bank Your Insights</h2>
          <h3 class="section_subheading">
            Secure Full page, HTML, Texts and Images
          </h3>
          <p>
            Secure full web pages, HTML, selected texts, and images from the
            websites you visit. BoomConsole allows you to capture the entire
            content of a webpage, including HTML, specific texts, and images.
            Add your personal insights and thoughts to these saved web pages,
            organizing them in your personalized filing system for easy access
            and retrieval. Whether you're conducting research, gathering
            inspiration, or compiling resources for a project, BoomConsole
            ensures your insights are well-documented and easily navigable.
          </p>
          <p>
            <a
              class="btn btn-featured btn-featured-mini"
              routerLink="/explore/insights">
              Explore
              <span class="material-symbols-outlined"> chevron_right </span>
            </a>
          </p>
        </div>

        <div class="col-md-6 video_wrapper order-md-2 order-1">
          <img width="624" height="560" src="./assets/bj/full-page.png" />
        </div>
      </div>
    </div>
  </section>

  <section id="feature_webjourney" class="section_chatgpt sec">
    <div class="container my-4">
      <div class="row align-items-center row-gap">
        <div class="col-md-6 video_wrapper order-md-1 order-1">
          <img width="624" height="560" src="./assets/bj/links-bookmarks.png" />
        </div>
        <div class="col-md-6 px-5 order-md-2 order-2">
          <h2 class="section_heading">Bank your Web Journey</h2>
          <h3 class="section_subheading">File Urls and bookmarks</h3>
          <p>
            Secure your web journey by describing and filing the URLs you visit
            and adding notes to describe these websites. Access your bookmarks
            from multiple browsers and file them into your web organizer.
          </p>
          <p>
            <a
              class="btn btn-featured btn-featured-mini"
              routerLink="/explore/web-journey">
              Explore
              <span class="material-symbols-outlined"> chevron_right </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="feature_secure_search" class="section_chatgpt sec">
    <div class="container my-4">
      <div class="row align-items-center row-gap">
        <div class="col-md-6 px-5 order-md-1 order-2">
          <h2 class="section_heading">Secure data search</h2>
          <h3 class="section_subheading">Search, Find, Organize</h3>
          <p>
            Search, Find, Organize, View and Review the insights in your safe.
            Access and search your data from anywhere with BoomConsole's
            advanced search feature. Quickly find and review saved content, even
            outside BoomConsole’s browser, ensuring your information is always
            at your fingertips.Our powerful search functionality allows you to
            search your chats, emails, documents, contacts, and notes from any
            devices.
          </p>
          <p>
            <a
              class="btn btn-featured btn-featured-mini"
              routerLink="/explore/secure-search">
              Explore
              <span class="material-symbols-outlined"> chevron_right </span>
            </a>
          </p>
        </div>
        <div class="col-md-6 video_wrapper order-md-2 order-1">
          <img width="624" height="560" src="./assets/bj/search-folder.png" />
        </div>
      </div>
    </div>
  </section>

  <div class="container section-benefits py-5">
    <div class="row text-center">
      <h2>Who can Benefit?</h2>
      <p>Bank Dollar$ with BoomConsole</p>
    </div>

    <div class="keen-slider te" #sliderRef>
      <div class="benefit keen-slider__slide">
        <div
          class="py-4 px-3 my-3 border border-dark-subtle rounded d-flex flex-column align-items-center">
          <div class="benefit_icon">
            <img
              width="100"
              height="100"
              src="./assets/home/3d-icons/individual.png"
              alt="icon"
              class="img-fluid w-50" />
          </div>
          <div class="benefit_title">
            <h3 class="mt-2 mb-4">Individuals</h3>
          </div>

          <p>
            BoomConsole is ideal for freelancers, students, and professionals
            who spend substantial time on online research and content creation.
            Easily store AI chat interactions and educational materials. Share
            with friends and groups to make collaboration both enjoyable and
            productive.
          </p>
        </div>
      </div>
      <div class="benefit keen-slider__slide">
        <div
          class="py-4 px-3 my-3 border border-dark-subtle rounded d-flex flex-column align-items-center">
          <div class="benefit_icon">
            <img
              width="100"
              height="100"
              src="./assets/home/3d-icons/megaphone-dynamic-color.png"
              alt="icon"
              class="img-fluid w-50" />
          </div>
          <div class="benefit_title">
            <h3 class="mt-2 mb-4">Marketing Professionals</h3>
          </div>
          <p>
            Digital marketers and business development officers can streamline
            online content and contact management with BoomConsole. Keep track
            of important contacts from LinkedIn, Google Maps, Indeed, and
            Monster.com, ensuring you never lose valuable connections.
          </p>
        </div>
      </div>
      <div class="benefit keen-slider__slide">
        <div
          class="py-4 px-3 my-3 border border-dark-subtle rounded d-flex flex-column align-items-center">
          <div class="benefit_icon">
            <img
              width="100"
              height="100"
              src="./assets/home/3d-icons/zoom-dynamic-color.png"
              alt="icon"
              class="img-fluid w-50" />
          </div>
          <div class="benefit_title">
            <h3 class="mt-2 mb-4">Researchers</h3>
          </div>
          <p>
            BoomConsole empowers researchers by enhancing research efficiency,
            note-taking, and data extraction capabilities. Trace URLs, add notes
            to websites, and capture essential information seamlessly,
            optimizing your research process.
          </p>
        </div>
      </div>
      <div class="benefit keen-slider__slide">
        <div
          class="py-4 px-3 my-3 border border-dark-subtle rounded d-flex flex-column align-items-center">
          <div class="benefit_icon">
            <img
              width="100"
              height="100"
              src="./assets/home/3d-icons/pencil-dynamic-color.png"
              alt="icon"
              class="img-fluid w-50" />
          </div>
          <div class="benefit_title">
            <h3 class="mt-2 mb-4">Writers</h3>
          </div>
          <p>
            Writers benefit from BoomConsole's ability to edit and export AI
            chat interactions into various formats such as Word, PDF, CSV, and
            more. Gather inspiration and increase productivity with powerful
            research capabilities tailored for writers.
          </p>
        </div>
      </div>
      <div class="benefit keen-slider__slide">
        <div
          class="py-4 px-3 my-3 border border-dark-subtle rounded d-flex flex-column align-items-center">
          <div class="benefit_icon">
            <img
              width="100"
              height="100"
              src="./assets/home/3d-icons/notebook-dynamic-color.png"
              alt="icon"
              class="img-fluid w-50" />
          </div>
          <div class="benefit_title">
            <h3 class="mt-2 mb-4">Law Firms</h3>
          </div>
          <p>
            Data security is paramount for law firms and professionals handling
            sensitive information. BoomConsole offers secure data management,
            allowing you to safely store web content, chats, and documents,
            ensuring your information remains protected.
          </p>
        </div>
      </div>
    </div>

    <div class="dots">
      <button
        (click)="slider?.moveToIdx(i)"
        *ngFor="let slide of dotHelper; let i = index"
        [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
    </div>
  </div>

  <div class="container pb-4">
    <div class="row text-center">
      <h2>FAQ</h2>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-7">
        <div class="accordin-section">
          <h2 class="accordion">What does BoomConsole do?</h2>
          <div class="panel">
            <p>
              BoomConsole is a web management tool that helps you to save and
              organize your online contents, chats, contacts with folders and
              descriptions.
            </p>
          </div>
        </div>
        <div class="accordin-section">
          <h2 class="accordion">
            Is BoomConsole Extension available for free?
          </h2>
          <div class="panel">
            <p>
              Yes, but in your free plan, you can only capture up to 100 chats
              in Chat GPT.
            </p>
          </div>
        </div>

        <div class="accordin-section">
          <h2 class="accordion">How do I install BoomConsole?</h2>
          <div class="panel">
            <p>You have two ways to install BoomConsole:</p>
            <p><strong>1. Directly from the Web Store:</strong></p>
            <ul>
              <li>Visit the Chrome Web Store (or Edge/Brave Web Store).</li>
              <li>Search for "BoomConsole" in the search bar.</li>
              <li>
                Click on the "Add to Chrome/Edge/Brave" button to install
                BoomConsole directly to your browser.
              </li>
            </ul>
            <p><strong>2. From our homepage:</strong></p>
            <ul>
              <li>
                Look for the "Add to Chrome" button on top of the home page.
              </li>
              <li>
                This also directs you to Chrome web store simply "Add to
                Chrome/Edge/Brave" to install it .
              </li>
            </ul>
          </div>
        </div>
        <div class="accordin-section">
          <h2 class="accordion">Can I become an agent of BoomConsole?</h2>
          <div class="panel">
            <p>
              Yes, certainly. BoomConsole allows you to become three types of
              agents:
            </p>
            <p>
              Seller's Agent: They help sellers list their products and market
              to potential buyers.
            </p>
            <p>
              Buyer's Agent: They guide buyers in identifying products and
              negotiating favorable terms and conditions.
            </p>
            <p>
              Listing Agent: They list the seller's product in their local
              language.
            </p>
            <p>
              Simply click on "Become an Agent" and provide a short reason for
              becoming an agent. Boom Console will accept your request within 24
              hours.
            </p>
          </div>
        </div>
        <div class="accordin-section">
          <h2 class="accordion">How can I earn money from BoomConsole?</h2>
          <div class="panel">
            <p>BoomConsole offers you multiple ways to make money:</p>
            <ol>
              <li>
                <strong>Mentor:</strong> Invite friends through the system and
                become their mentor.
              </li>
              <li>
                <strong>Agent:</strong> You can also apply to become an agent
                and receive commission of your purchase.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="contact_boomconsole"
    class="container contact-form-container my-5 py-5">
    <div class="row text-center">
      <h2>Contact Us</h2>
      <p>info&#64;boomconsole.com</p>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-7">
        <form
          id="contact-form"
          [formGroup]="contactUsForm"
          #createFormId="ngForm"
          (ngSubmit)="submitMessaage(createFormId)">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              class="form-control"
              placeholder="Chandler"
              autocomplete="off"
              required
              formControlName="name" />
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              class="form-control"
              placeholder="admin@boomconsole.com"
              autocomplete="off"
              required
              formControlName="email" />
          </div>

          <div class="form-group">
            <label for="message">Message</label>
            <textarea
              id="message"
              name="message"
              class="form-control"
              placeholder="Description....."
              rows="4"
              required
              formControlName="message"></textarea>
          </div>

          <ng-template #loading>
            <button [disabled]="true" class="btn btn-primary btn-disabled">
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">.</span>
              </div>
              Sending...
            </button>
          </ng-template>
          <button
            *ngIf="!isApiRequesting; else loading"
            type="submit"
            class="btn btn-primary">
            Contact Now
          </button>
        </form>
      </div>
      <div class="col-md-5 py-3 flex-column align-items-md-start">
        <img
          width="502"
          height="340"
          src="./assets/home/3d-icons/contactus-02.png"
          alt="agent 3d avatar of a girl"
          class="img-fluid" />
      </div>
    </div>
  </div>
  <section class="agent-section my-5 py-5">
    <div class="container">
      <div class="row pt-5 pb-3">
        <div class="col-md-6">
          <h2>Earn money as an Agent</h2>
          <p>Become a BoomConsole agent and earn money as an affiliate.</p>
          <a
            routerLink="/become-agent"
            class="btn btn-success mt-3 mb-4 btn-main-cta"
            >Become an agent</a
          >
        </div>
        <div class="col-md-6 text-center agent-image">
          <img
            width="338"
            height="500"
            class="agent-avatar"
            src="./assets/home/agent-avatar.png"
            alt="" />
        </div>
      </div>
    </div>
    <!-- </div> -->
  </section>

  <app-main-footer></app-main-footer>
</div>

<ng-template #content let-modal>
  <span class="btn-close" (click)="modal.dismiss('Cross click')"></span>
  <div class="modal-body p-0">
    <iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/aDfidy7wVQQ?si=SqgXzxbEEnC69Q5x"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen></iframe>
  </div>
</ng-template>
