import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationSoundUrl = 'assets/sounds/notificationSound.mp3';

  constructor(
    private _socketService: SocketService,
    private _authService: AuthService
  ) {
    if (this._authService.isLoggedIn()) {
      this.initializeSocketListeners();
    }
  }

  initializeSocketListeners() {
    this._socketService
      .on('notification')
      .subscribe(async (notification: any) => {
        console.warn(notification);
        const audio = new Audio(this.notificationSoundUrl);
        try {
          await audio.play();
        } catch (error) {
          console.error('Error playing notification sound:', error);
        }
      });
  }
}
