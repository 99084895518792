<div class="modal-header">
  <h4 class="modal-title">Group Members</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"></button>
</div>
<div class="modal-body">
  <ul>
    <li *ngFor="let member of groupMembers">
      <img
        class="profile-img"
        *ngIf="member?.profileImage; else noProfileImage"
        [src]="member?.profileImage"
        alt="" />
      <ng-template #noProfileImage>
        <img class="profile-img" src="./assets/man.png" alt="" />
      </ng-template>

      <div>
        <span>{{ member.firstName }} {{ member.lastName }}</span>
        <p class="member-email">{{ member.email }}</p>
      </div>
    </li>
    <ng-template #defaultProfile>
      <img
        src="https://apitest.boomconcole.com/uploads/f5542452-47ea-4c1d-9a30-6e0305961a17.png"
        alt="Profile Image"
        class="profile-img" />
    </ng-template>
  </ul>
  <div class="leave-group" *ngIf="!isOwner">
    <button
      class="btn btn-primary"
      style="align-items: center"
      (click)="leaveGroup()">
      Leave Group
    </button>
  </div>
</div>
