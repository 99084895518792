import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { GroupService } from '../../my-groups/services/group.service';
import { SnackbarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-group-members',
  templateUrl: './group-members.component.html',
  styleUrls: ['./group-members.component.scss'],
})
export class GroupMembersComponent {
  @Input() groupMembers: any[] = [];
  @Input() groupId: any;
  @Input() isOwner!: boolean;
  entityId: any;
  conceptId: any;

  constructor(
    public activeModal: NgbActiveModal,
    private _route: ActivatedRoute,
    private _auth: AuthService,
    private _groupService: GroupService,
    private _snackbarService: SnackbarService,
    private router: Router
  ) {
    const user = this._auth.loadFromLocalStorage();
    this.conceptId = user.userConcept;
    this.entityId = user.entityId;
  }

  close() {
    this.activeModal.dismiss();
  }

  leaveGroup() {
    const payload = [this.entityId];
    this._groupService.leaveGroup(this.groupId, payload).subscribe({
      next: () => {
        this._snackbarService.openSnackBar(
          'Left group successfully!',
          'success'
        );
        this.close();
        
      },
      error: error => {
        this._snackbarService.openSnackBar('Failed to leave group.', 'error');
        console.error('Error leaving group:', error);
      },
    });
  }
}
