<div class="container main-header">
  <div class="row">
    <div class="col-md-5 col-6">
      <a class="navbar-brand" routerLink="/">
        <img
          width="300"
          height="62"
          src="./assets/boomcosole-wide-logo.png"
          alt="Boomconsole logo"
          class="img-fluid" />
      </a>
      <span class="badge badge-pill badge-warning mx-2">beta</span>
    </div>
    <div class="col-md-7 col-6">
      <div class="d-flex justify-content-end align-items-center gap-3 ms-3">
        <a
          id="chromeExtensionLink"
          href="https://chrome.google.com/webstore/detail/boomconsole/gpmcahkaejaehjhalogpakgcnoholepc"
          target="_blank"
          title="Download Boomconsole Chrome Extension"
          class="btn mr-3 btn-download py-2 d-none d-md-block">
          <img
            src="{{ imgUrl }}"
            alt="logo of chrome browser"
            width="24"
            height="24" />
          Add to {{ browserName }}
        </a>

        <a
          *ngIf="isLoggedIn; else notLoggedIn"
          class="btn btn-success btn-main-cta"
          type="button"
          routerLink="/captures/boomgpt"
          >Your Account</a
        >
        <ng-template #notLoggedIn>
          <a class="btn btn-success btn-main-cta" routerLink="/login"
            >Sign In</a
          >
        </ng-template>
      </div>
    </div>
  </div>
</div>
<app-messages-box></app-messages-box>
