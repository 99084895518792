import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatGPTAllGroupComponent } from './doctypes/chat-gpt-all/chat-gpt-all.component';
import { ChatgptGroupComponent } from './doctypes/chatgpt/chatgpt.component';
import { DocumentGroupComponent } from './doctypes/document/document.component';
import { ImageGroupComponent } from './doctypes/image/image.component';
import { PollDataGroupComponent } from './doctypes/poll-data/poll-data.component';
import { VisualTextGroupComponent } from './doctypes/visual-text/visual-text.component';
import { RouterModule } from '@angular/router';
import { DefaultTypeGroupComponent } from './doctypes/default-type/default-type.component';
import { MainFooterComponent } from './main-footer/main-footer.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { HomeLeftSidebarComponent } from './home-left-sidebar/home-left-sidebar.component';
import { HomeRightSidebarComponent } from './home-right-sidebar/home-right-sidebar.component';
import { UserCommentsComponent } from './partials/user-comments/user-comments.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SignupModalComponent } from './signup-modal/signup-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UTCToLocalTimePipe } from 'src/app/pipes/utcto-local-time.pipe';
import { VerifyHeaderComponent } from './verify-header/verify-header.component';
import { FolderTreeComponent } from './folder-tree/folder-tree.component';
import { MessagesBoxComponent } from 'src/app/shared/messages-box/messages-box.component';
import { AllChatMessagesComponent } from 'src/app/shared/messages-box/all-chat-messages/all-chat-messages.component';
import {  NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GroupChatComponent } from 'src/app/shared/messages-box/group-chat/group-chat.component';
import { GroupMembersComponent } from '../messages/group-members/group-members.component';

@NgModule({
  declarations: [
    ChatGPTAllGroupComponent,
    ChatgptGroupComponent,
    DocumentGroupComponent,
    ImageGroupComponent,
    PollDataGroupComponent,
    VisualTextGroupComponent,
    DefaultTypeGroupComponent,
    MainFooterComponent,
    MainHeaderComponent,
    HomeLeftSidebarComponent,
    HomeRightSidebarComponent,
    UserCommentsComponent,
    SignupModalComponent,
    VerifyHeaderComponent,
    FolderTreeComponent,
    MessagesBoxComponent,
    GroupMembersComponent,
    AllChatMessagesComponent,
    GroupChatComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    UTCToLocalTimePipe,
    NgbModule,
    NgxSkeletonLoaderModule.forRoot({
      animation: 'pulse',
      theme: {
        background: '#b6bed3',
        
      },
    }),
  ],
  exports: [
    ChatGPTAllGroupComponent,
    ChatgptGroupComponent,
    DocumentGroupComponent,
    ImageGroupComponent,
    PollDataGroupComponent,
    VisualTextGroupComponent,
    DefaultTypeGroupComponent,
    MainFooterComponent,
    MainHeaderComponent,
    HomeLeftSidebarComponent,
    HomeRightSidebarComponent,
    SignupModalComponent,
    VerifyHeaderComponent,
    FolderTreeComponent,
    MessagesBoxComponent,
    AllChatMessagesComponent,
    GroupChatComponent,
    GroupMembersComponent,
  ],
})
export class SharedModule {}