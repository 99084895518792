import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
  isLoggedIn: Boolean = false;
  browserName: string = '';
  imgUrl: string = './assets/home/chrome-logo.svg'

  constructor(
    private _authService: AuthService,
  ) {

  }

  ngOnInit(): void {
    if (this._authService.isLoggedIn()) {
      this.isLoggedIn = true;
    }

    this.detectBrowserName()
  }

  detectBrowserName() {
    let browserInfo = navigator.userAgent;
    let browser;
    if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
      browser = 'Opera';
      this.imgUrl = './assets/home/opera.svg'
    } else if (browserInfo.includes('Edg')) {
      browser = 'Edge';
      this.imgUrl = './assets/home/edg.png'
    } else if (browserInfo.includes('Chrome')) {
      browser = 'Chrome';
      this.imgUrl = './assets/home/chrome-logo.svg'
    } else if (browserInfo.includes('Safari')) {
      browser = 'Safari';
      this.imgUrl = './assets/home/safari.png'
    } else if (browserInfo.includes('Firefox')) {
      browser = 'Firefox'
      this.imgUrl = './assets/home/firefox.png'
    } else {
      browser = 'Chrome'
      this.imgUrl = './assets/home/chrome-logo.png'
    }

    this.browserName = browser
  }
}
