import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent {
  isLoggedIn: Boolean = false;

  constructor(private _authService: AuthService) { }

  ngOnInit(): void {
    if (this._authService.isLoggedIn()) {
      this.isLoggedIn = true;
    }
  }
}
