
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GetLink, MakeTheInstanceConcept } from 'mftsccs-browser';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ItemService } from 'src/app/services/item.service';
import { linkerFriend } from 'src/app/shared/constants/linkers.const';
import { IUser } from 'src/app/shared/interfaces/IUser.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  @Output() modalClosed = new EventEmitter<string>();

  baseUrl: string = environment.baseUrl;
  notLoading: boolean = true;

  jwthelper = new JwtHelperService();
  currentUser!: IUser;
  errorMessage!: string;
  loginError: Boolean = false;
  billing: any;

  subscriptionInfo: any;

  socialUser!: any;
  isLoggedin?: boolean;

  auth2: any;
  @ViewChild('loginRef', { static: true }) loginElement!: ElementRef;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
  });

  affiliateCode: string = '';
  inviterData: any = {};

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private route: ActivatedRoute,
    private _itemService: ItemService,
    public _activeModal: NgbActiveModal,
  ) {
    if (this._auth.isLoggedIn()) {
      this._router.navigate(['/captures/boomgpt']);
    }
  }
  ngOnInit(): void {
    this.googleAuthSDK();
    this.route.queryParams.subscribe((params: any) => {
      if (params.email) {
        this.loginForm.patchValue({
          email: params.email,
        });
      }
      if (params.hasOwnProperty('bc')) {
        this.affiliateCode = params.bc;
      }
      if (
        params.hasOwnProperty('invite') ||
        sessionStorage.getItem('inviterData')
      ) {
        const inviteEncryptedData =
          params.invite || sessionStorage.getItem('inviterData');

        sessionStorage.setItem('inviterData', inviteEncryptedData);

        this.inviterData = JSON.parse(window.atob(inviteEncryptedData));
        this.affiliateCode = this.inviterData?.inviterCode;
      }
    });

    if (this.affiliateCode == '') {
      let storedData: any = localStorage.getItem('currentAffCode');
      let parsedData = JSON.parse(storedData);
      this.affiliateCode = parsedData ? parsedData.affiliate_code : null;
    }

  }

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }

  async handleCredentialResponse(response: any) {
    this.loginForm.disable();
    const formData = new FormData();
    formData.append('access_token', response.credential);
    formData.append(
      'affiliate_code',
      this.affiliateCode ? this.affiliateCode : '',
    );
    const isPlanSelected = localStorage.getItem('plan');
    let google_login_obs = this._auth.googleLogin(formData);
    this.loginHandler(google_login_obs, isPlanSelected);
  }

  googleAuthSDK() {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id:
        environment.google_client_id,
      callback: this.handleCredentialResponse.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });
    // @ts-ignore
    google.accounts.id.renderButton(
      // @ts-ignore
      this.loginElement.nativeElement,
      { theme: 'outline', size: 'large', width: '100%' },
    );
    // @ts-ignore
    google.accounts.id.prompt((notification: PromptMomentNotification) => { });
  }


  onSubmit() {
    this._auth.resetProfile();

    var login_model = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
    const isPlanSelected = localStorage.getItem('plan');

    if (!this.loginForm.invalid) {
      this.notLoading = false;
      let obs = this._auth.login(login_model);
      this.loginHandler(obs, isPlanSelected);
    }
  }

  loginHandler(obs: Observable<any>, isPlanSelected: any) {
    obs.subscribe({
      next: async (x: any) => {
        console.log(x, 'asdas dasd sad');

        let userProfile: IUser = {
          pkg: '',
          token: x.data.token,
          refreshToken: x.data.refreshtoken,
          email: x?.data?.email,
          userId: x.data.entity[0].id,
          userConcept: x.data?.userConcept,
          theUserId: x?.data?.entity[0].userId,
          amcode: window.btoa(
            JSON.stringify(this.getFilteredPermission(x?.data?.roles)),
          ),
        };
        this._auth.saveToLocalStorage(userProfile);

        if (x?.isNewUser) {
          await this.initializeUser(x?.data?.entity[0].userId);
          await this.getMyCoupons(x?.data?.email, x?.data?.userConcept);
          if (Object.keys(this.inviterData).length)
            await this.updateRelationship({
              relatedData: x?.data?.userConcept,
            });
          this._router.navigate(['/captures/boomgpt']);
        } else {
          window.location.reload();
        }

        /**
         * Home page is  /captures/boomgpt
         */
        // this._router.navigate(['/captures/boomgpt']);
        // window.location.reload();
        // if (this.billing === 'order') this._router.navigate(['/subscription']);
        // else this._router.navigate(['/account']);
        if (isPlanSelected) this._router.navigate(['/subscription']);
        // localStorage.removeItem('plan')
        //    this.notLoading = true;
        // setTimeout(() => {
        //   this.listPayments();
        // }, 100);
      },
      error: (err: any) => {
        this.loginError = true;
        this.errorMessage = "Sorry, couldn't login at the moment.";
        this.notLoading = true;
      },
    });
  }
  closeModal() {
    this._activeModal.dismiss('Cross click');
  }

  async getMyCoupons(signupEmail: any, userConceptId: any) {
    return new Promise(async (resolve: any, reject: any) => {
      const conceptData = await MakeTheInstanceConcept(
        'onboarding_email',
        signupEmail,
        false,
        999,
        4,
        999,
      );
      const couponData = await GetLink(
        conceptData?.id,
        'assinged_coupon_s',
        10,
        1,
      );

      const freeCoupon: any = couponData?.filter((item: any) => {
        if (item?.data?.CoupounData?.PercentOff === '100') {
          const currenTime = new Date().getTime();
          const expireTime = new Date(
            item?.data?.CoupounData?.RedeemBy,
          ).getTime();
          if (expireTime > currenTime) return item;
        }
      });

      if (freeCoupon?.length) {
        const couponName = freeCoupon?.[0]?.data?.CoupounData?.Name;
        const response = await this.applyFullDiscount(
          userConceptId,
          couponName,
        );
        resolve(response);
      } else {
        resolve('');
      }
    });
  }

  applyFullDiscount(userConceptId: string, productCouponCode: string) {
    return new Promise(async (resolve: any, reject: any) => {
      const formdata: any = new FormData();
      formdata.append('ConceptUserId', userConceptId);
      formdata.append('CoupounCode', productCouponCode);

      // for (const value of formdata.values()) {
      //   console.log(value);
      // }

      this._itemService.directDiscount(formdata).subscribe({
        next: (response: any) => {
          console.log('response directDiscount', response);
          resolve(response);
        },
        error: (err: any) => {
          console.log(err);
          reject(err);
        },
      });
    });
  }


  getFilteredPermission(roles: any[]) {
    let permissions: any[] = [];
    roles.forEach((elem: any) => {
      let elem_perm =
        elem?.internal_role_name?.internal_role_name_s_has_permission_s;
      if (elem_perm?.length > 0) permissions = [...permissions, ...elem_perm];
    });

    return permissions;
  }

  listPayments() {
    const paymentList = this._itemService.listAPIClean('Stripepayment');
    paymentList.subscribe({
      next: (data: any) => {
        const profile = localStorage.getItem('profile');
        // let payer:string
        if (profile) {
          const payer = JSON.parse(profile).theUserId;
          // filter by userId
          let userPaymentsList = data.filter(
            (item: any) => item?.data?.Stripepayment?.userId == payer,
          );
          // filter by entryDate to list expDate
          userPaymentsList = userPaymentsList.filter(
            (item: any) => item.data.Stripepayment?.entryDate,
          );
          // sort by entryDate to get latest payment completion
          userPaymentsList.sort(
            (a: any, b: any) =>
              (b.data.Stripepayment?.entryDate
                ? new Date(b.data.Stripepayment?.entryDate).getTime()
                : 0) -
              (a.data.Stripepayment?.entryDate
                ? new Date(a.data.Stripepayment?.entryDate).getTime()
                : 0),
          );

          if (userPaymentsList.length) {
            const paymentInfo = userPaymentsList[0].data.Stripepayment;
            this.subscriptionInfo = {
              // plan: paymentInfo.Description,
              // plan: paymentInfo.Description === 'Free' ? 'Free' : (paymentInfo.Description === 'Personal' ? 'Personal' : 'Professional'),
              plan: paymentInfo.Description,
              expDate: paymentInfo.ExpDate,
            };
          } else {
            this.subscriptionInfo = {
              plan: 'Free',
              expDate: 'Forever',
            };
          }
        }
        localStorage.setItem(
          'subscription',
          JSON.stringify(this.subscriptionInfo),
        );
      },
      error: (err: any) => {
        console.log(err);
      },
      // complete: () => {
      // }
    });
  }

  initializeUser(user_id: any) {
    return new Promise((resolve: any, reject: any) => {
      this._itemService.createDefaultFolders(user_id).subscribe({
        next: (x: any) => {
          resolve(x);
        },
        error: (err: any) => {
          console.log(err);
          reject(err);
        },
      });
    });
  }

  updateRelationship(responseData: any) {
    return new Promise((resolve: any, reject: any) => {
      const linkData: any = {
        compositionId1: Number(this.inviterData?.inviterConceptId), // inviters's userConceptId
        compositionId2: Number(responseData?.relatedData), // signup user's userConceptId
        linker: linkerFriend, // ie. 'friend'
      };

      this._itemService
        .createSemanticConnectionWithoutAuth(linkData)
        .subscribe({
          next: (response: any) => {
            resolve(response);
          },
          error: (err: any) => {
            console.log(err);
            reject(err);
          },
          complete: () => { },
        });
    });
  }
}
