import { Component, Inject, Injectable } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(message: string, messageType: 'success' | 'error' | 'warning' | 'info'): void {
    const config = new MatSnackBarConfig();
    config.duration = 4000;
    config.horizontalPosition= 'end',
    config.verticalPosition= 'top'


    switch (messageType) {
      case 'success':
        config.panelClass = ['snackbar-success'];
        config.data = { symbol: 'check_circle' }; 
        break;
      case 'error':
        config.panelClass = ['snackbar-error'];
        config.data = { symbol: 'cancel' }; 
        break;
      case 'warning':
        config.panelClass = ['snackbar-warning'];
        config.data = { symbol: 'warning' }; 
        break;
      case 'info':
        config.panelClass = ['snackbar-info'];
        config.data = { symbol: 'info' }; 
        break;
    }

    this.snackBar.openFromComponent(SnackbarComponent, {
      ...config,
      data: { message, symbol: config.data?.symbol, color: config.data?.color  },
    });
  }
}

