import { Injectable, OnDestroy } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService implements OnDestroy {
  private socket: Socket;
  private socketSubscription!: Subscription;
  conceptId: any;

  constructor(private _auth: AuthService) {
    const baseUrl = environment.socketUrl;
    // this.socket = io(baseUrl);
    this.socket = io(baseUrl, {
      path: '/socket',
      reconnectionAttempts: 5,
    });
    this.conceptId = this._auth.loadFromLocalStorage().userConcept;
    const entityId = this._auth.loadFromLocalStorage()?.entityId;
    const isLoggedIn = this._auth.isLoggedIn();
    if (isLoggedIn) {
      this.socket.emit('user-connect', entityId);
    }
  }

  emit(event: string, data: any) {
    this.socket.emit(event, data);
  }

  on(event: string): Observable<any> {
    return new Observable(observer => {
      this.socket.on(event, data => {
        observer.next(data);
      });

      return () => {
        this.socket.off(event);
      };
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from socket events when component is destroyed
    if (this.socketSubscription) {
      this.socketSubscription.unsubscribe();
    }
  }
}
