<div class="home-page">
  <app-main-header></app-main-header>
  <section class="position-relative py-5 text-center">
    <div class="row">
      <h1>Page Not Found</h1>
      <p>Sorry, the page you are looking for is not available.</p>
      <div class="col-md-12">
        <img width="580" src="./assets/page-not-found.png" />
      </div>
    </div>
    <p>
      <a
        class="btn btn-success my-1 mx-2 mx-sm-2 mx-md-3 btn-sub-cta"
        routerLink="/">
        <span class="material-symbols-outlined"> keyboard_backspace </span>
        Back to Home
      </a>
    </p>
  </section>

  <app-main-footer></app-main-footer>
</div>
