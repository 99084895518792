<app-main-header class="head"></app-main-header>
<div class="row justify-content-center align-items-center whole">
  <div class="col-xl-6 home_main_body">
    <ng-template #loading>
      <div class="loading">
        <span class="loader"></span>
      </div>
    </ng-template>
    <ng-container *ngIf="dataLoaded; else loading">
      <div class="card mb-3 all-content">
        <div class="row align-items-center">
          <div class="col profile_img_container">
            <img class="rounded-circle" [src]="image" />
            <div class="flex-grow-1">
              <div class="post-item-profile-name">
                <h5>{{ username }}</h5>
              </div>
            </div>
          </div>

          <div class="col card-body d-flex flex-row gap-2 my-0 view-photo">
            <div class="shared-item-entry mb-5 view-content">
              <small *ngIf="items?.source_url"><strong>source:</strong>
                <a [href]="items?.source_url" target="_blank">
                  {{ items?.source_url }}</a></small>
              <ng-container *ngIf="items?.category">
                <p>
                  <span style="font-weight: bold">Category:</span>
                  {{ items?.category.data || items?.category }}
                </p>
              </ng-container>

              <ng-container *ngIf="items?.data_type == 'Image'">
                <img [src]="items?.filepath" />
              </ng-container>

              <ng-container *ngIf="items?.data_type == dataType.TracedUrls">
                <ol>
                  <li *ngFor="let url_data of items?.traced_urls">
                    <a class="external_link" target="_blank" [href]="url_data">
                      {{ url_data }}</a>
                  </li>
                </ol>
              </ng-container>
              <ng-container *ngIf="
                    items?.data_type == dataType.VisualText ||
                    items?.data_type == dataType.HtmlOnly ||
                    items?.data_type == dataType.ALL
                  ">
                <p [innerHTML]="items?.visual_text" class="overflow-wrap">
                  <span style="font-weight: bold" *ngIf="items?.visual_text">Visual text:</span>
                </p>
              </ng-container>
              <ng-container *ngIf="items?.data_type == dataType.FullPage">
                <p [innerHTML]="items?.visual_text" class="overflow-wrap">
                  <span style="font-weight: bold" *ngIf="items?.visual_text">Visual text:</span>

                </p>
              </ng-container>


              <ng-container *ngIf="
                    items?.data_type == dataType.Chatgpt ||
                    items?.data_type == dataType.GoogleBard ||
                    items?.data_type == dataType.YouChat ||
                    items?.data_type == dataType.PoeChat
                  ">
                <div class="chat_gpt">
                  <div class="chatgpt_answers" [innerHTML]="items?.answer"></div>
                </div>
              </ng-container>

              <ng-container *ngIf="
                    items?.data_type == dataType.linkedin ||
                    items?.data_type == dataType.indeed ||
                    items?.data_type == dataType.monster ||
                    items?.data_type == dataType.maps||
                    items?.data_type == dataType.crm
                  ">
                <div class="d-flex align-items-center flex-column details_container">
                  <div class="d-flex flex-column w-100">
                    <div class="top_profile ">
                      <img *ngIf="crmContactInfo?.contact?.avatar; else defaultImage"
                        src="{{crmContactInfo?.contact?.avatar}}"
                        alt="{{ crmContactInfo?.contact?.name || crmContactInfo?.company?.name }}" class="avatar_img" />
                      <ng-template #defaultImage>
                        <img src="/assets/man.png" alt="" class="avatar_img" />
                      </ng-template>

                      <div>
                        <h1 class="text-center" style="font-size: 26px;">{{ crmContactInfo?.contact?.name ||
                          crmContactInfo?.company?.name }}</h1>
                        <p *ngIf="crmContactInfo?.contact?.email">{{ crmContactInfo?.contact?.email}}</p>
                        <p *ngIf="crmContactInfo?.company?.emails">{{
                          crmContactInfo?.company?.emails[0]?.email }}</p>
                      </div>
                    </div>










                    <div class="w-100">
                      <div class="border-t-2">
                        <div *ngIf="crmContactInfo?.contact?.name || crmContactInfo?.company?.name" class="info-item">
                          <span class="text-gray-600">Full Name</span>
                          <p class="text-gray-800"> {{crmContactInfo?.contact?.name || crmContactInfo?.company?.name
                            }}</p>
                        </div>

                        <div *ngIf="crmContactInfo?.contact?.phones?.[0]?.number" class="info-item">
                          <span class="text-gray-600">Phone</span>
                          <div class="text-gray-800" *ngFor="let phone of crmContactInfo?.contact?.phones">
                            <p class="text-gray-800">{{phone.number}}</p>
                          </div>
                        </div>

                        <!-- PHONE -->
                        <div *ngIf="crmContactInfo?.company?.phone?.length > 0" class="info-item">
                          <span class="text-gray-600">Phone</span>
                          <div class=" text-gray-800">
                            <p class="text-gray-800" *ngFor="let phone of crmContactInfo?.company?.phone">{{
                              phone.phone }}
                            </p>
                          </div>
                        </div>

                        <!-- ADDRESSES -->
                        <div *ngIf="crmContactInfo?.contact?.addresses" class="info-item">
                          <span class="text-gray-600">Location</span>
                          <div *ngFor="let location of crmContactInfo?.contact?.addresses">
                            <p class="text-gray-800"> {{ location.location}}</p>
                          </div>
                        </div>

                        <!-- LOCATION -->
                        <div *ngIf="crmContactInfo?.company?.location" class="info-item">
                          <span class="text-gray-600">Location</span>
                          <br>
                          <p class="text-gray-800">{{ crmContactInfo?.company?.location}}</p>
                        </div>

                        <div *ngIf="items?.crm_data?.googleLocation" class="info-item">
                          <span class="text-gray-600">Google Location</span>
                          <p class="text-gray-800">{{items?.crm_data?.googleLocation}}
                          </p>
                        </div>

                        <div *ngIf="crmContactInfo?.contact?.link" class="info-item">
                          <span class="text-gray-600">Link</span>
                          <br>
                          <a href="{{crmContactInfo?.contact?.link.startsWith('http') ? crmContactInfo?.contact?.link : 'https://' + crmContactInfo?.contact?.link}}"
                            target="_blank" class="" rel="noreferrer" class="text-gray-800">
                            {{crmContactInfo?.contact?.link }}
                          </a>
                        </div>

                        <!-- SECTOR -->
                        <div *ngIf="crmContactInfo?.company?.sector" class="info-item">
                          <span class="text-gray-600">Sector</span>
                          <p class="text-gray-800">{{crmContactInfo?.company?.sector }}</p>
                        </div>

                        <!-- URL -->
                        <div *ngIf="crmContactInfo?.contact?.url" class="mb-3" class="info-item">
                          <span class="text-gray-600">Url</span>
                          <a href="{{crmContactInfo?.contact?.url.startsWith('http') ? crmContactInfo?.contact?.url : 'https://' + crmContactInfo?.contact?.url}}"
                            target=" _blank" class="text-break" rel="noreferrer">
                            <p class="text-gray-800"> Visit {{crmContactInfo?.contact?.name}} on Google maps</p>
                          </a>
                        </div>

                        <!-- WEBSITE -->
                        <div
                          *ngIf="crmContactInfo?.contact?.companyWebsite || crmContactInfo?.company?.website || crmContactInfo?.contact?.website"
                          class="info-item">
                          <span class="text-gray-600">Website</span>
                          <br>
                          <a class="text-gray-800"
                            href="{{(crmContactInfo?.contact?.companyWebsite || crmContactInfo?.company?.website || crmContactInfo?.contact?.website)?.startsWith('http') ? (crmContactInfo?.contact?.companyWebsite || crmContactInfo?.company?.website || crmContactInfo?.contact?.website) : 'https://' + (crmContactInfo?.contact?.companyWebsite || crmContactInfo?.company?.website || crmContactInfo?.contact?.website)}}"
                            target="_blank" class="" rel="noreferrer">
                            {{crmContactInfo?.contact?.companyWebsite || crmContactInfo?.company?.website ||
                            crmContactInfo?.contact?.website}}
                          </a>
                        </div>
                      </div>

                      <div class="border-t-2  bg-gray-100">
                        <div class="info-item" *ngIf="crmContactInfo?.contact?.ceo">
                          <span class=" text-gray-600">CEO</span>
                          <p class="text-gray-800">{{ crmContactInfo?.contact?.ceo }}</p>
                        </div>

                        <div class="info-item" *ngIf="crmContactInfo?.contact?.founded">
                          <span class=" text-gray-600">Founded</span>
                          <p class="text-gray-800">{{ crmContactInfo?.contact?.founded }}</p>
                        </div>

                        <div class="info-item" *ngIf="crmContactInfo?.contact?.industry">
                          <span class=" text-gray-600">Industry</span>
                          <p class="text-gray-800">{{ crmContactInfo?.contact?.industry }}</p>
                        </div>

                        <div class="info-item" *ngIf="crmContactInfo?.contact?.companySize">
                          <span class=" text-gray-600">Company Size</span>
                          <p class="text-gray-800">{{ crmContactInfo?.contact?.companySize }}</p>
                        </div>

                        <div class="info-item" *ngIf="crmContactInfo?.contact?.companyRevenue">
                          <span class=" text-gray-600">Company Revenue</span>
                          <p class="text-gray-800">{{ crmContactInfo?.contact?.companyRevenue }}</p>
                        </div>

                        <div class="info-item" *ngIf="crmContactInfo?.contact?.ratingAndReviews">
                          <span class=" text-gray-600">Rating and Reviews</span>
                          <p class="text-gray-800">{{ crmContactInfo?.contact?.ratingAndReviews }}</p>
                        </div>

                        <div class="info-item" *ngIf="crmContactInfo?.contact?.workWellbeing">
                          <span class=" text-gray-600">Workwell Being</span>
                          <p class="text-gray-800">{{ crmContactInfo?.contact?.workWellbeing }}</p>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="crmContactInfo?.company?.contactPersons?.length > 0" class="notes_details mt-5">
                      <h4>Contact person</h4>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Designation</th>
                            <th scope="col">Link</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let person of crmContactInfo?.company?.contactPersons; let i = index">
                            <th scope="row">{{i}}</th>
                            <td>{{person?.firstName}} {{person?.lastName}}</td>
                            <td>
                              <ul class="list-unstyled ps-0" *ngFor="let email of person?.emails">
                                <li>{{email?.email}}</li>
                              </ul>
                            </td>
                            <td>
                              <ul class="list-unstyled ps-0" *ngFor="let phone of person?.phones">
                                <li class="">{{phone?.phone}}</li>
                              </ul>
                            </td>
                            <td>{{person?.designation}}</td>
                            <td>
                              <ul class="list-unstyled ps-0" *ngFor="let link of person?.personLink">
                                <li>
                                  <a href="{{link?.link.startsWith('http') ? link?.link : 'https://' + link?.link}}"
                                    target="_blank">
                                    {{link?.link}}
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div *ngIf="crmContactInfo?.contact?.notes?.length > 1; else noNote" class="notes_details pe-3">
                      <div class="contact_activity">
                        <p>Note</p>
                        <div *ngFor="let data of crmContactInfo?.contact?.notes">
                          <div *ngIf="data?.title " class="inner_contact shadow position-relative">
                            <div class="edit_note">
                              <div class="w-100 p-3">
                                <p class="note-title">
                                  {{ data?.title }}
                                </p>
                                <span class="note-details">
                                  {{ data?.details }}
                                </span>
                              </div>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ng-template #noNote>
                      <div class="w-100 shadow p-2">
                        <p class="fw-bold">Note</p>
                        <span class="material-symbols-outlined">
                          description
                        </span>
                        <p class="fw-bold">
                          No notes available
                        </p>
                      </div>
                    </ng-template>

                    <div *ngIf="crmContactInfo?.contact?.about" class="about_sec">
                      <div class="contact_activity">
                        <p>About</p>
                        <div class="inner_contact shadow">
                          <p>
                            {{crmContactInfo?.contact?.about }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="crmContactInfo?.contact?.experience">
                      <div class="contact_activity">
                        <p>Experience</p>
                        <div class="inner_contact shadow">
                          <p>
                            {{crmContactInfo?.contact?.experience}}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="crmContactInfo?.contact?.education">
                      <div class="contact_activity">
                        <p>Education</p>
                        <div class="inner_contact shadow">
                          <p>
                            {{ crmContactInfo?.contact?.education}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div>
                    <a
                      [href]="items?.crm_data?.link"
                      target="_blank"
                      class="link"
                      >{{ items?.crm_data?.link }}</a
                    >
                    <div class="name" *ngIf="items?.crm_data?.name">
                      <span class="fw-bold">Name: </span>
                      {{ items?.crm_data?.name }}
                    </div>
                    <div class="position" *ngIf="items?.crm_data?.email">
                      <span class="fw-bold">Email: </span>
                      {{ items?.crm_data?.email }}
                    </div>
                    <div
                      class="position"
                      *ngIf="items?.crm_data?.companyWebsite"
                    >
                      <span class="fw-bold">Website: </span>
                      <a
                        [href]="items?.crm_data?.companyWebsite"
                        target="_blank"
                        class="link"
                        >{{ items?.crm_data?.companyWebsite }}</a
                      >
                    </div>
                    <div class="position" *ngIf="items?.crm_data?.url">
                      <span class="fw-bold">URL: </span>
                      <a
                        [href]="items?.crm_data?.url"
                        target="_blank"
                        class="link"
                        >{{ items?.crm_data?.url }}</a
                      >
                    </div>
                    <div *ngIf="items?.crm_data?.ceo">
                      <span class="fw-bold">CEO</span>
                      <p cla5ss="">
                        {{ items?.crm_data?.ceo }}
                      </p>
                    </div>

                    <div *ngIf="items?.crm_data?.founded">
                      <span class="fw-bold">Founded</span>
                      <p class="">
                        {{ items?.crm_data?.founded }}
                      </p>
                    </div>

                    <div *ngIf="items?.crm_data?.industry">
                      <span class="fw-bold">Industry</span>
                      <p class="">
                        {{ items?.crm_data?.industry }}
                      </p>
                    </div>

                    <div *ngIf="items?.crm_data?.companySize">
                      <span class="fw-bold">Company Size</span>
                      <p class="">
                        {{ items?.crm_data?.companySize }}
                      </p>
                    </div>

                    <div *ngIf="items?.crm_data?.companyRevenue">
                      <span class="fw-bold">Company Revenue</span>
                      <p class="">
                        {{ items?.crm_data?.companyRevenue }}
                      </p>
                    </div>

                    <div *ngIf="items?.crm_data?.ratingAndReviews">
                      <span class="fw-bold">Rating and reviews</span>
                      <p class="">
                        {{ items?.crm_data?.ratingAndReviews }}
                      </p>
                    </div>
                    <div *ngIf="items?.crm_data?.phone?.[0]">
                      <span class="fw-bold">Phone</span>
                      {{
                        items?.crm_data?.phone[0]?.number ||
                          items?.crm_data?.phone[0]?.data?.number
                      }}
                    </div>

                    <div
                      class="section shadow p-2"
                      *ngIf="items?.crm_data?.about"
                    >
                      <div
                        class="section-content"
                        [innerHTML]="
                          formatTextWithLineBreaksAndBoldFirst(
                            items?.crm_data?.about,
                            'About'
                          )
                        "
                      ></div>
                    </div>

                    <div
                      class="section shadow p-2"
                      *ngIf="items?.crm_data?.education"
                    >
                      <div
                        class="section-content"
                        [innerHTML]="
                          formatTextWithLineBreaksAndBoldFirst(
                            items?.crm_data?.about,
                            'Education'
                          )
                        "
                      ></div>
                    </div>

                    <div
                      class="section shadow p-2"
                      *ngIf="items?.crm_data?.experience"
                    >
                      <div
                        class="section-content"
                        [innerHTML]="
                          formatTextWithLineBreaksAndBoldFirst(
                            items?.crm_data?.experience,
                            'Experience'
                          )
                        "
                      ></div>
                    </div>
                  </div>
                  </div> -->
                </div>

              </ng-container>

              <ng-container *ngIf="items?.data_type == dataType.OnlyImages">
                <div class="only_images row">
                  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let item of items?.images?.data">
                    <div class="only_images_item_wrap shared-item-entry mb-5">
                      <img *ngIf="item?.data?.path" [src]="item?.data?.path" />
                    </div>
                  </div>
                </div>
              </ng-container>


              <div class="image-card" *ngIf="items?.images">
                <ng-container class="images-container" *ngFor="let image of items?.images; let i = index">
                  <div class="card image" style="width: 18rem">
                    <a href="{{ image?.path }}" target="_blank">
                      <img src="{{ image?.path }}" class="card-img-top" alt="{{ image?.title }}" />
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="!isLoggedIn">
  <footer class="footer">
    <div class="container add">
      <h1>Sign in or sign up on BoomConsole to effortlessly organize your web</h1>
      <div class="col-md-7 col-6">
        <div class="d-flex justify-content-end align-items-center gap-3 ms-3">
          <a routerLink="/login" class="btn mr-3 btn-login py-2 d-none d-md-block">
            Sign In
          </a>
          <p style="margin-top: 20px">or</p>
          <a class="btn btn-primary btn-main-cta" type="button" routerLink="/signup">Sign Up</a>
        </div>
      </div>
    </div>
  </footer>
</ng-container>