import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUser } from '../shared/interfaces/IUser.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl: string = environment.baseUrl;
  jwthelper = new JwtHelperService();

  userProfile: BehaviorSubject<IUser> = new BehaviorSubject<IUser>({
    token: '',
    refreshToken: '',
    userId: '',
    email: '',
    amcode: '',
    userConcept: '',
    theUserId: '',
    pkg: '',
  });

  userMenus: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    private _router: Router
  ) {}

  isLoggedIn(): boolean {
    const user = this.loadFromLocalStorage();
    return !!user?.token;
    // return !this.jwthelper.isTokenExpired(user.token);
  }

  hasPermission(url: string): boolean {
    const urlArr = url.split('/');
    const user = this.loadFromLocalStorage();
    const allowed_modules = JSON.parse(window.atob(user.amcode));

    const allowed_menus = allowed_modules?.map((item: any) => {
      return item?.internal_permission?.module_url;
    });
    return allowed_menus.includes('/' + urlArr[1]);
  }

  login(model: any) {
    return this.http.post(this.baseUrl + '/auth/login', model);
  }

  googleLogin(requestOptions: any) {
    return this.http.post(this.baseUrl + '/google-login-v2', requestOptions);
  }

  signup(model: any) {
    return this.http.post(this.baseUrl + '/auth/signup', model);
  }

  admin_signup(model: any) {
    return this.http.post(this.baseUrl + '/createAdminUser', model);
  }

  refreshToken() {
    const fromStorage = this.loadFromLocalStorage();
    const model = {
      accessToken: fromStorage.token,
      refreshToken: fromStorage.refreshToken,
    };
    return this.http.post(this.baseUrl + '/auth/refresh', model);
  }

  saveToLocalStorage(user: IUser) {
    this.userProfile.next(user);
    localStorage.setItem('profile', JSON.stringify(user));
    window.dispatchEvent(new Event('set_storage'));
  }

  loadFromLocalStorage(): IUser {
    if (!this.userProfile.value.token) {
      const fromLocalStorage = localStorage.getItem('profile');
      if (fromLocalStorage) {
        const userInfo = JSON.parse(fromLocalStorage);
        this.userProfile.next(userInfo);
      }
    }
    return this.userProfile.value;
  }

  resetProfile() {
    localStorage.removeItem('profile');
    this.userProfile.next({
      token: '',
      refreshToken: '',
      userId: '',
      email: '',
      amcode: '',
      userConcept: '',
      theUserId: '',
      pkg: '',
    });
  }

  getUserProfile() {
    const user = this.loadFromLocalStorage();
    const userId = user.userConcept;
    return this.http.get(this.baseUrl + `/edit-api-clean?id=${userId}`);
  }

  logout() {
    this.resetProfile();
    localStorage.removeItem('plan');
    localStorage.removeItem('subscription');
    localStorage.removeItem('currentAffCode');
    window.dispatchEvent(new Event('logout_from_extension'));
    this._router.navigate(['login']);
    window.location.reload();
  }
}
