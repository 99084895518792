import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { GroupMembersComponent } from 'src/app/modules/messages/group-members/group-members.component';
import { GroupService } from 'src/app/modules/my-groups/services/group.service';
import { SnackbarService } from 'src/app/services/snack-bar.service';
import { SocketService } from 'src/app/services/socket.service';
import { Message, MessageType } from '../../interfaces/IMessage.interface';
import { ModalImageComponent } from 'src/app/modules/messages/modal-image/modal-image.component';

@Component({
  selector: 'app-all-chat-messages',
  templateUrl: './all-chat-messages.component.html',
  styleUrls: ['./all-chat-messages.component.scss'],
})
export class AllChatMessagesComponent implements OnInit, OnDestroy {
  private messageSubscription!: Subscription;
  messages: any[] = [];
  newMessage: string = '';
  conceptId: any;
  entityId: any;
  myDMUsers: any[] = [];
  @Input() selectedDM: any;

  userId: any;
  groupId: any;
  isOwner: boolean = false;
  isMember: boolean = false;
  groupName: string = '';
  userDetails: any;
  scrolling: boolean = false;
  editingMessage: Message | null = null;
  groupMembers: any;
  @ViewChild('chatArea') chatArea!: ElementRef;
  previewImage: string | ArrayBuffer | null = null;
  file: any;
  fileUrl: any;
  token: string;
  formData: any;
  pdf: any;
  groupImage!: string;
  page: number = 1;

  messageBoxOpen = false;
  isLoggedIn = false;
  activeMenu = 2;
  myGroups: any[] = [];
  selectedGroup: any = null;
  scrolledToBottom: boolean = true;
  messagesUpdated: boolean = false;
  loadNewMessages: boolean = true;
  receiver: any;
  name: string = '';
  @Output() closeChat = new EventEmitter<void>();

  isApiInProgress: boolean = true;
  isApiInProgressGD: boolean = true;
  isApiInProgressUser: boolean = true;
  isApiInProgressDM: boolean = true;
  count: number = 10;
  @ViewChild('messageInput') messageInput!: ElementRef;
  itemData: any;

  constructor(
    private _auth: AuthService,
    private modalService: NgbModal,
    private _groupService: GroupService,
    private socketService: SocketService,
    private _snackbarService: SnackbarService,
    private elementRef: ElementRef
  ) {
    const user = this._auth.loadFromLocalStorage();
    this.conceptId = user.userConcept;
    this.entityId = user.entityId;
    this.userId = user.theUserId;
    this.token = user.token;
    this.isLoggedIn = this._auth.isLoggedIn();
    this.entityId = this._auth.loadFromLocalStorage()?.entityId;
  }

  ngOnInit(): void {
    this.groupId = this.selectedDM?.groupId;
    this.getuserDMs(0);
    this.updateMessages();
    this.getDMUserDetail();
    this.deleteMessageSocket();
    this.updateMessageSocket();
    this.elementRef.nativeElement.addEventListener(
      'scroll',
      this.onScroll.bind(this)
    );
  }

  enterEditMode(message: Message): void {
    if (!message.text || message.text.trim() === '') {
      this._snackbarService.openSnackBar('Cannot edit', 'info');
      this.cancelEdit();
      return;
    }
    this.editingMessage = message;
    this.newMessage = message.text;
    this.focusInput();
  }

  downloadFile(fileUrl: string, filename: string): void {
    const fileExtension = fileUrl.split('.').pop() || '';
    if (fileExtension === 'pdf') {
      filename = 'ultimate.pdf';
    } else if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
      filename = `image.${fileExtension}`;
    }

    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error downloading file:', error);
      });
  }

  openGroupMembers() {
    const modalRef = this.modalService.open(GroupMembersComponent, {
      centered: true,
    });
    modalRef.componentInstance.groupMembers = this.groupMembers;
    modalRef.componentInstance.groupId = this.groupId;

    modalRef.componentInstance.isOwner = this.isOwner;
  }

  adjustInputHeight(event?: Event) {
    const textarea = event
      ? (event.target as HTMLTextAreaElement)
      : (document.querySelector('.chat-input') as HTMLTextAreaElement);

    if (textarea && textarea.value.trim() === '') {
      textarea.style.height = '40px';
      textarea.style.overflowY = 'hidden';
    } else if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;

      if (textarea.scrollHeight > 120) {
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.overflowY = 'hidden';
      }
    }
  }
  openImageModal(imageUrl: string, sender: any): void {
    const modalRef = this.modalService.open(ModalImageComponent, {
      centered: true,
    });
    modalRef.componentInstance.imageUrl = imageUrl;
    modalRef.componentInstance.sender = sender;
  }
  
  updateMessage(): void {
    if (!this.newMessage.trim() || !this.editingMessage) {
      return;
    }

    const updatedText = this.newMessage.trim();
    const messageId = this.editingMessage.messageId;
    const Id = this.editingMessage.id;
    const createdAt = this.editingMessage.createdAt;
    const groupId = this.groupId;

    this._groupService
      .updateMessage(messageId, Id, updatedText, createdAt)
      .subscribe({
        next: () => {
          this._snackbarService.openSnackBar('Message Updated', 'success');
          this.socketService.emit('update-message', {
            messageId,
            groupId,
            updatedText,
          });
          this.resetTextareaHeight();
        },
        error: error => {
          if (
            error.error &&
            error.error.message ===
              'Cannot update message after 15 minutes of creation.'
          ) {
            this._snackbarService.openSnackBar(
              'Cannot update message after 15 minutes of creation.',
              'error'
            );
            this.resetTextareaHeight();
          } else {
            this._snackbarService.openSnackBar(
              'An error occurred while updating the message.',
              'error'
            );
          }
          this.cancelEdit();
        },
      });
  }

  resetTextareaHeight(): void {
    const textarea = document.querySelector(
      '.chat-input'
    ) as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = '40px';
      textarea.style.overflowY = 'hidden';
    }
  }

  deleteMessage(message: Message): void {
    console.log(message, 'messagessssss');

    if (!message) {
      return;
    }

    const groupId = this.groupId;
    const messageId = message.messageId;
    this.socketService.emit('delete-message', { groupId, messageId });
    this._groupService.deleteDM(groupId, messageId).subscribe({
      next: (response: any) => {
        this.cancelEdit();
        this._snackbarService.openSnackBar(response?.message, 'success');
        // this.messages = this.messages.filter(m => m.messageId !== messageId);
      },
      error: (error: any) => {
        console.error('Error deleting message:', error);
        this._snackbarService.openSnackBar('Error deleting message', 'error');
      },
    });
  }

  deleteMessageSocket() {
    this.socketService.on('delete-message').subscribe((messageId: any) => {
      this.messages = this.messages.filter(m => m.messageId !== messageId);
    });
  }

  updateMessageSocket() {
    this.socketService.on('update-message').subscribe((data: any) => {
      const index = this.messages.findIndex(
        m => m.messageId == data?.messageId
      );
      if (index !== -1) {
        this.messages[index].text = data?.updatedText;
      }
      this.cancelEdit();
    });
  }

  getDMUserDetail() {
    this._groupService.getDMUserDetail(this.groupId, this.entityId).subscribe({
      next: (x: any) => {
        this.isApiInProgressUser = false;
        this.receiver = x?.data?.receiver;
        const sender = x?.data?.sender;
        this.name = this.receiver?.name;
        const entityId = this.entityId;
        const groupMembersId = [this.receiver?.entityId];
        this.joinGroup(groupMembersId);

        this.userDetails = {
          conceptId: entityId,
          firstName: sender?.name,
          lastName: '',
          profileImg: sender?.image,
        };
      },
      error: () => {
        this.isApiInProgressUser = false;
      },
    });
  }

  closeDM() {
    this.selectedDM = null;
    this.messageBoxOpen = false;
    this.activeMenu = 2;
    this.closeChat.emit();
  }

  onScroll(): void {
    const element = this.chatArea.nativeElement;

    if (element.scrollTop === 0) {
      const currentScrollHeight = element.scrollHeight;

      this.page++;
      if (this.loadNewMessages) {
        this.getGroupMessages(currentScrollHeight);
      }
    }
  }

  getGroupMessages(currentScrollHeight: number): void {
    this._groupService.fetchGroupMessages(this.page, this.groupId).subscribe({
      next: (x: any) => {
        this.isApiInProgress = false;
        if (x?.messages?.length > 0) {
          this.messages = [...(x?.messages || []), ...this.messages];
          setTimeout(() => {
            const newScrollHeight = this.chatArea.nativeElement.scrollHeight;
            this.chatArea.nativeElement.scrollTop =
              newScrollHeight - currentScrollHeight;
          }, 0);
        } else {
          this.loadNewMessages = false;
        }

        if (this.page === 1) {
          this.messagesUpdated = true;
        }
      },
    });
  }
  ngOnDestroy() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  receiveMessages() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }

    this.messageSubscription = this.socketService
      .on('receive-group-message')
      .subscribe((data: any) => {
        if (data.groupId === this.groupId) {
          if (data.sender?.conceptId == this.conceptId) data['isOwner'] = true;
          if (!Array.isArray(this.messages)) {
            this.messages = [];
          }
          this.messages.push(data);
          this.scrollToBottom();
        }
      });

    this.socketService.on('update-group-message-ids').subscribe(data => {
      const message = this.messages.find(msg => msg.tempId == data.tempId);
      if (message) {
        message.messageId = data.messageId;
        message.id = data.id;
        delete message.tempId;
      }
    });
  }

  getuserDMs(currentScrollHeight: number) {
    this._groupService.fetchDMs(this.page, this.groupId).subscribe({
      next: (x: any) => {
        this.isApiInProgressDM = false;
        if (x?.messages?.length > 0) {
          this.messages = [...(x?.messages || []), ...this.messages];
          setTimeout(() => {
            const newScrollHeight = this.chatArea.nativeElement.scrollHeight;
            this.chatArea.nativeElement.scrollTop =
              newScrollHeight - currentScrollHeight;
          }, 0);
        } else {
          this.loadNewMessages = false;
        }

        if (this.page === 1) {
          this.messagesUpdated = true;
        }
      },
      error: () => {
        this.isApiInProgress = false;
      },
    });
  }
  cancelEdit(): void {
    this.editingMessage = null;
    this.newMessage = '';
  }
  sendMessageDM() {
    if (!this.newMessage.trim() && !this.file) {
      return;
    }

    const message: MessageType = {
      text: this.newMessage.trim(),
      groupId: this.groupId,
      userId: this.userId,
      entityId: this.entityId,
      createdAt: new Date().toISOString(),
      token: this.token,
      groupImage: this.groupImage,
      sender: {
        conceptId: this.conceptId,
        firstName: this.userDetails?.firstName,
        lastName: this.userDetails?.lastName,
        profileImg: this.userDetails?.profileImg,
        entityId: this.entityId,
      },
      type: 'privateMessage',
    };

    if (this.file) {
      message.attachments = {
        file: this.file,
        filename: this.file.name,
        type: this.file.type,
      };
    }

    this.socketService.emit('create-group-message', message);
    this.newMessage = '';
    this.file = null;
    this.pdf = null;
    this.previewImage = null;
    this.resetTextareaHeight();
    setTimeout(() => {
      this.scrollToBottom();
    }, 0);
  }
  updateMessages() {
    this.messages = [];
    this.receiveMessages();
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.chatArea.nativeElement.scrollTop =
          this.chatArea.nativeElement.scrollHeight;
        this.page = 1;
      }, 0);
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }
  joinGroup(groupMembers: any) {
    this.socketService.emit('join-group', {
      groupId: this.groupId,
      groupMembers: groupMembers,
    });
  }
  onInput(event: any) {
    this.newMessage = event.target.value;
  }
  handleFileInput(event: any) {
    this.file = event.target.files[0];

    if (this.file) {
      if (this.file.size > 2 * 1024 * 1024) {
        this._snackbarService.openSnackBar(
          'File size exceeds 2MB limit',
          'warning'
        );
        return;
      }
      const reader = new FileReader();
      if (this.file.type.startsWith('image')) {
        reader.onload = (e: any) => {
          this.previewImage = e.target.result as ArrayBuffer;
        };
        reader.readAsDataURL(this.file);
      }

      if (this.file.type.startsWith('application/pdf')) {
        this.pdf = this.file.name;
      }
      this.focusInput();
    } else {
      this.remove();
    }
    event.target.value = '';
  }
  focusInput() {
    if (this.messageInput) {
      this.messageInput.nativeElement.focus();
    }
  }

  remove(): void {
    this.file = null;           
    this.pdf = null;            
    this.previewImage = null;   
    this.fileUrl = null;       
  }
  
}
