import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { GroupService } from 'src/app/modules/my-groups/services/group.service';
import { SocketService } from 'src/app/services/socket.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-messages-box',
  templateUrl: './messages-box.component.html',
  styleUrls: ['./messages-box.component.scss'],
})
export class MessagesBoxComponent implements OnInit {
  messageBoxOpen = false;
  @ViewChild('messageBox') messageBox!: ElementRef;
   @ViewChild('messageBoxGroup') messageBoxGroup!: ElementRef;
  loadingMore: boolean = false;
  searchAll: Subject<string> = new Subject<string>();
  searchGroup: Subject<string> = new Subject<string>();
  newMessageIds: Set<string> = new Set<string>();

  entityId: any;
  searchTerm: string = '';
  isLoggedIn = false;
  messagesUpdated: boolean = false;

  activeMenu = 1;
  myDMUsers: any[] = [];
  page: number = 1;
  loadNewMessages: boolean = false;

  myGroups: any[] = [];
  @Output() closeChat = new EventEmitter<void>();
  selectedGroup: any = null;
  selectedDM: any;
  hasMoreData: boolean = true;
  groupId: any;
  isApiInProgress: boolean = true;
  isApiInProgressDM: boolean = true;
  count: number = 10;
  itemData: any[] = [];
  searching: boolean = false;
  noUserFound: boolean = false;
  conceptId: any;
  hasMoreDataMessages: boolean = true; 
  hasMoreDataGroups: boolean = true;

  notifications: any[] = [];
  unreadCount: number = 0;
  groupUnreadCounts: { [key: string]: number } = {};
  private originalTitle: any;

  constructor(
    private _auth: AuthService,
    private _groupService: GroupService,
    private socketService: SocketService,
    private title: Title
  ) {
    this.searchAll.pipe(debounceTime(1000), distinctUntilChanged()).subscribe({
      next: (x: any) => {
        this.search(x);
      },
    });

    this.originalTitle = this.title.getTitle();

    this.searchGroup
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe({
        next: (x: any) => {
          this.searchGroups(x);
        },
      });
  }

  ngOnInit(): void {
    this.isLoggedIn = this._auth.isLoggedIn();

    setTimeout(() => {
      this.entityId = this._auth.loadFromLocalStorage()?.entityId;
      this.getRecentMessageAll(this.entityId, this.page, this.count); 
      this.getRecentMessageGroup(this.entityId, this.page, this.count);
      this.getNewMessages();
      this.updateTitleForNotifications(0);
    }, 3000);
  }
  onScroll() {
    const scrollContainer = this.messageBox.nativeElement;
    const threshold = 100;

    if (
      scrollContainer.scrollTop + scrollContainer.clientHeight >=
        scrollContainer.scrollHeight - threshold &&
      !this.loadingMore
    ) {
      this.isApiInProgress = false;

      this.loadMoreItems();
    }
  }

  onScrollGroup() {
    const scrollContainer = this.messageBoxGroup.nativeElement;
    const threshold = 100;

    if (
      scrollContainer.scrollTop + scrollContainer.clientHeight >=
        scrollContainer.scrollHeight - threshold &&
      !this.loadingMore
    ) {
      this.isApiInProgress = false;

      this.loadMoreItemsGroup();
    }
  }

  loadMoreItems() {
    if (this.hasMoreDataMessages) { 
      this.loadingMore = true;
      this.count += 10;

      this.getRecentMessageAll(this.entityId, this.page, this.count);

      setTimeout(() => {
        this.loadingMore = false;
      }, 500);
    }
  }

  loadMoreItemsGroup() {
    if (this.hasMoreDataGroups) { 
            this.loadingMore = true;
      this.count += 10;

      this.getRecentMessageGroup(this.entityId, this.page, this.count);

      setTimeout(() => {
        this.loadingMore = false;
      }, 500);
    }
  }

  handleChatItemClick(group: any): void {
    if (group.type === 'groups') {
      this.openGroupChat(group);
    } else if (group.type === 'private') {
      this.openDM(group);
    }
  }

  searchall = (e: any) => this.searchAll.next(e);
  searchGr = (e: any) => this.searchGroup.next(e);

  openGroupChat(group: any) {
    this.selectedGroup = group;
    console.warn('SELECTED GROUP', this.selectedGroup);
    this.selectedDM = null;
    this.readNewMessages(group.groupId);
  }

  getRecentMessageGroup(entityId: any, page: number, count: number): void {
    this.isApiInProgressDM = true;

    this.searching = true;
    this._groupService
      .getRecentMessageGroup(entityId, page, count, 'groups')
      .subscribe({
        next: (response: any) => {
          this.isApiInProgressDM = false;
          this.searching = false;
          if (response.data.length < count) {
            this.hasMoreDataGroups = false;          }
          this.myGroups = response.data.map((message: any) => {
            message.message.isNew = this.newMessageIds.has(message.groupId);

            return message;
          });
        },
        error: (error: any) => {
          console.error('Error fetching recent messages:', error);
          this.isApiInProgressDM = false;
          this.searching = false;
        },
      });
  }

  openDM(group: any) {
    this.selectedDM = group;
    this.selectedGroup = null;
    this.readNewMessages(group.groupId);
  }

  close() {
    this.closeChat.emit();
  }

  closeGroupChat() {
    this.selectedGroup = null;
  }

  toggleMessage() {
    this.messageBoxOpen = !this.messageBoxOpen;
  }

  handleCloseChat() {
    this.selectedGroup = null;
    this.selectedDM = null;
    this.isApiInProgress = false;
    this.getRecentMessageAll(this.entityId, this.page, this.count);
    this.getRecentMessageGroup(this.entityId, this.page, this.count);
  }

  onTabChange(tabId: number) {
    this.activeMenu = tabId;
  }

  search(event: any) {
    const keyword = event.target.value.trim();
    const type = 'all';

    if (keyword === '') {
      this.noUserFound = false;
      this.searching = false;
      this.getRecentMessageAll(this.entityId, this.page, this.count);
    } else {
      this.searching = true;
      this.noUserFound = false;

      this._groupService.searchGroupDM(this.entityId, type, keyword).subscribe({
        next: (response: any) => {
          this.searching = false;
          this.itemData = response.data;

          if (!this.itemData || this.itemData.length === 0) {
            this.noUserFound = true;
          } else {
            this.noUserFound = false;
          }
        },
        error: () => {
          this.searching = false;
          this.noUserFound = true;
        },
      });
    }
  }

  searchGroups(event: any) {
    const keyword = event.target.value.trim();
    const type = 'groups';

    if (keyword === '') {
      this.noUserFound = false;
      this.searching = false;
      this.getRecentMessageGroup(this.entityId, this.page, this.count);
    } else {
      this.searching = true;
      this.noUserFound = false;

      this._groupService.searchGroupDM(this.entityId, type, keyword).subscribe({
        next: (response: any) => {
          this.searching = false;
          this.myGroups = response.data;

          if (!this.myGroups || this.myGroups.length === 0) {
            this.noUserFound = true;
          } else {
            this.noUserFound = false;
          }
        },
        error: () => {
          this.searching = false;
          this.noUserFound = true;
        },
      });
    }
  }

  getMyDMUsers() {
    this._groupService.getMyDMUsers(this.entityId).subscribe({
      next: (x: any) => {
        this.isApiInProgressDM = false;
        this.myDMUsers = x?.data;
      },
      error: error => {
        console.error('Error fetching DM users:', error);
      },
    });
  }

  getRecentMessageAll(entityId: any, page: number, count: number): void {
    this.isApiInProgress = true;

    this.searching = true;
    this._groupService.getRecentMessageAll(entityId, page, count).subscribe({
      next: (response: any) => {
        this.isApiInProgress = false;
        this.searching = false;

        if (response.data.length < count) {
          this.hasMoreDataMessages = false;        }

        this.itemData = response.data.map((message: any) => {
          message.message.isNew = this.newMessageIds.has(message.groupId);
          return message;
        });
      },
      error: (error: any) => {
        console.error('Error fetching recent messages:', error);
        this.isApiInProgress = false;
        this.searching = false;
      },
    });
  }
  getAll() {
    this.getRecentMessageAll(this.entityId, this.page, this.count);
    this.getRecentMessageGroup(this.entityId, this.page, this.count);
  }
  getNewMessages() {
    this.socketService.on('new-message').subscribe((data: any) => {
      if (!data || !data.id || !data.message) {
        console.warn('Received invalid message data:', data);
        return;
      }

      data.isNew = true;
      this.notifications.push(data);

      this.newMessageIds.add(data.id);

      if (this.groupUnreadCounts[data.id]) {
        this.groupUnreadCounts[data.id] += 1;
      } else {
        this.groupUnreadCounts[data.id] = 1;
      }

      if (
        this.selectedDM?.groupId == data.id ||
        this.selectedGroup?.groupId == data.id
      )
        return;

      this.unreadCount += 1;
      this.updateMessageData(data);
      this.updateTitleForNotifications(this.notifications.length);
    });
  }

  updateMessageData(data: any) {
    const groupIndex = this.myGroups.findIndex(m => m.groupId === data.id);
    const itemIndex = this.itemData.findIndex(m => m.groupId === data.id);

    if (groupIndex !== -1) {
      this.myGroups[groupIndex].message.messageText = data.message;
      this.myGroups[groupIndex].message.isNew = true;
    }

    if (itemIndex !== -1) {
      this.itemData[itemIndex].message.messageText = data.message;
      this.itemData[itemIndex].message.isNew = true;
    }
  }

  updateTitleForNotifications(count: number) {
    this.title.setTitle(`( ${count} ) ${this.originalTitle}`);
    if (count === 0) {
      this.title.setTitle(this.originalTitle);
    }
  }

  readNewMessages(groupId: string) {
    this.newMessageIds.delete(groupId);

    this.notifications = this.notifications.filter(notification => {
      if (notification.id === groupId) {
        this.unreadCount -= this.groupUnreadCounts[groupId];
        delete this.groupUnreadCounts[groupId];
        return false;
      }
      return true;
    });

    this.updateTitleForNotifications(this.notifications.length);
  }
}
