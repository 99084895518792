<a
  class="floating_icon floating_icon_chatgpt"
  routerLink="/video-tutorial"
  [queryParams]="{ t: 'ChatGpt', st: 'Save' }">
  <span class="material-symbols-outlined"> play_circle </span>
  <img src="./assets/chatgpt.jpg" />
</a>
<a
  class="floating_icon floating_icon_bard"
  routerLink="/video-tutorial"
  [queryParams]="{ t: 'Gemini', st: 'Save' }">
  <span class="material-symbols-outlined"> play_circle </span>
  <img src="./assets/bard.png" />
</a>
<a
  class="floating_icon floating_icon_you"
  routerLink="/video-tutorial"
  [queryParams]="{ t: 'ChatGpt', st: 'Save' }">
  <span class="material-symbols-outlined"> play_circle </span>
  <img src="./assets/you-chat.png" />
</a>
<a
  class="floating_icon floating_icon_poe"
  routerLink="/video-tutorial"
  [queryParams]="{ t: 'ChatGpt', st: 'Save' }">
  <span class="material-symbols-outlined"> play_circle </span>
  <img src="./assets/poe-chat.svg" />
</a>
<img src="./assets/bj/featured_img_03.png" />
