'use strict';

export const consoleDefaultFolderLists = [
  {
    chatgpt_category: {
      category_name: 'Personal',
      category_parent: '',
      category_description: '',
    },
  },
  {
    chatgpt_category: {
      category_name: 'Work',
      category_parent: '',
      category_description: '',
    },
  },
  {
    chatgpt_category: {
      category_name: 'Family',
      category_parent: '',
      category_description: '',
    },
  },
  {
    chatgpt_category: {
      category_name: 'Chats',
      category_parent: '',
      category_description: '',
    },
  },
  {
    chatgpt_category: {
      category_name: 'Contacts',
      category_parent: '',
      category_description: '',
    },
  },
  {
    chatgpt_category: {
      category_name: 'Food',
      category_parent: '',
      category_description: '',
    },
  },
  {
    chatgpt_category: {
      category_name: 'Shopping',
      category_parent: '',
      category_description: '',
    },
  },
  {
    chatgpt_category: {
      category_name: 'Groups',
      category_parent: '',
      category_description: '',
    },
  },
  {
    chatgpt_category: {
      category_name: 'Events',
      category_parent: '',
      category_description: '',
    },
  },
];