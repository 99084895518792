import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  captureBoomGPTComposition,
  // chatGPTCategoryComposition,
} from 'src/app/shared/constants/composition.const';
// import { IBoomGpt, IFolder } from 'src/app/shared/models/boomgpt.model';

export const selectCaptures = createFeatureSelector<any>('mycaptures');

export const selectCaptureById = (
  captureId: number,
  composition_name: string
) =>
  createSelector(selectCaptures, (captures: any) => {
    if (typeof captures == 'undefined') {
      return null;
    }
    const data_array = captures?.data_array;
    const captureById = data_array?.filter((_: any) => _?.id == captureId);

    if (captureById?.length == 0) {
      return null;
    }

    return captureById?.[0];
  });

export const selectCaptureListsByIds = (captureIds: any[], source: string) =>
  createSelector(selectCaptures, (captures: any) => {
    if (typeof captures == 'undefined') {
      return null;
    }

    const capturesByIds: any[] = [];
    const data_array = captures?.data_array;
    captureIds.forEach((captureId: any) => {
      const captureById = data_array?.filter((_: any) => _?.id == captureId);
      capturesByIds.push(captureById[0]);
    });

    if (captureIds?.length == 0) {
      return null;
    }

    return capturesByIds;
  });

export const selectFilteredCaptures = (
  folder_id: string,
  search_keyword: string,
  composition_name: string,
  captureSortBy: string,
  sortByAsc: boolean,
  captureFilter: any
) =>
  createSelector(selectCaptures, (captures: any) => {
    if (typeof captures == 'undefined') {
      return null;
    }
    let filteredResults: any[] = [];

    if (search_keyword) {
      filteredResults = captures?.query_results;
      return filteredResults;
    }

    const data_array = captures?.data_array;

    // Get Data inside current Folder.
    const res: any = data_array?.filter((_: any) => {
      // const folder: IFolder = _?.data?.[composition_name]?.boom_folder;
      const folder: any = _?.boom_folder;
      // const folder: any = _;

      if (folder_id) {
        return String(folder?.category_id) == folder_id;
        // return String(folder?.parent) == folder_id;
      } else {
        return String(folder?.category_id) == '';
        // return String(folder?.parent) == '';
      }
    });

    const all_folders = data_array?.filter((item: any) => {
      // return !!item?.data?.[chatGPTCategoryComposition]?.category_name;
      return !!item?.name || item?.data_type;
    });

    const currentFolders = all_folders?.filter((item: any) => {
      if (folder_id) {
        return !!item?.name && item?.parent == folder_id;
      } else {
        return String(item?.parent) == '';
      }
    });

    const uniqueCurrentFolders = [
      ...new Map(currentFolders.map((item: any) => [item?.id, item])).values(),
    ];

    const sorted_currentFolders = uniqueCurrentFolders.sort(
      (a: any, b: any) => {
        const a_name = a?.name;
        const b_name = b?.name;
        return a_name?.localeCompare(b_name);
      }
    );

    //Add Datatypes to folders
    const currentFoldersWithDataType = sorted_currentFolders.map(
      (item: any) => {
        const x = {
          ...item,
          data_type: '1folder',
        };
        return x;
      }
    );

    const result = [...currentFoldersWithDataType, ...res];

    filteredResults = [...result];

    // filteredResults = result.filter(
    //   (x: any) =>
    //     (x?.data_type === '1folder' && captureFilter?.folder) ||
    //     ((x?.data?.[captureBoomGPTComposition]?.data_type ==
    //       'extension_youchat' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type ==
    //         'extension_poechat' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type ==
    //         'extension_googlebard' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type ==
    //         'extension_chatgpt') &&
    //       captureFilter?.aichat) ||
    //     (x?.data?.[captureBoomGPTComposition]?.data_type ==
    //       'extension_whatsapp_chat' &&
    //       captureFilter?.human_chat) ||
    //     ((x?.data?.[captureBoomGPTComposition]?.data_type == 'screenshot' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type == 'traced_url' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type == 'plain_text' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type == 'visual_text' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type == 'url' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type == 'only_images' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type == 'all_but_css' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type == 'all') &&
    //       captureFilter?.captures) ||
    //     ((x?.data?.[captureBoomGPTComposition]?.data_type == 'Document' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type == 'Image') &&
    //       captureFilter?.document_image) ||
    //     ((x?.data?.[captureBoomGPTComposition]?.data_type ==
    //       'extension_linkedin' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type ==
    //         'extension_whatsapp' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type ==
    //         'extension_indeed' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type == 'extension_maps' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type == 'web_crm' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type == 'full_page' ||
    //       x?.data?.[captureBoomGPTComposition]?.data_type ==
    //         'extension_monster') &&
    //       captureFilter?.crm),
    // );

    // switch (captureSortBy) {
    //   case 'type':
    //     {
    //       if (sortByAsc) {
    //         filteredResults.sort((a: any, b: any) => {
    //           let a_type =
    //             a?.data?.[captureBoomGPTComposition]?.data_type || a?.data_type;
    //           let b_type =
    //             b?.data?.[captureBoomGPTComposition]?.data_type || b?.data_type;

    //           return a_type?.localeCompare(b_type);
    //         });
    //       } else {
    //         filteredResults.sort((a: any, b: any) => {
    //           let a_type =
    //             a?.data?.[captureBoomGPTComposition]?.data_type || a?.data_type;
    //           let b_type =
    //             b?.data?.[captureBoomGPTComposition]?.data_type || b?.data_type;

    //           return b_type.localeCompare(a_type);
    //         });
    //       }
    //     }
    //     break;
    //   case 'title': {
    //     // if (sortByAsc) {
    //     //   filteredResults.sort((a: any, b: any) => {
    //     //     let a_title =
    //     //       a?.data[captureBoomGPTComposition]?.title ||
    //     //       a?.data[chatGPTCategoryComposition]?.category_name;
    //     //     let b_title =
    //     //       b?.data[captureBoomGPTComposition]?.title ||
    //     //       b?.data[chatGPTCategoryComposition]?.category_name;

    //     //     return a_title.localeCompare(b_title);
    //     //   });
    //     // } else {
    //     //   filteredResults.sort((a: any, b: any) => {
    //     //     let a_title =
    //     //       a?.data[captureBoomGPTComposition]?.title ||
    //     //       a?.data[chatGPTCategoryComposition]?.category_name;
    //     //     let b_title =
    //     //       b?.data[captureBoomGPTComposition]?.title ||
    //     //       b?.data[chatGPTCategoryComposition]?.category_name;

    //     //     return b_title.localeCompare(a_title);
    //     //   });
    //     // }
    //     if (sortByAsc) {
    //       filteredResults.sort((a: any, b: any) => {
    //         let a_title =
    //           a?.data?.[captureBoomGPTComposition]?.title ||
    //           a?.name;
    //         let b_title =
    //           b?.data?.[captureBoomGPTComposition]?.title ||
    //           b?.name;

    //         return a_title.localeCompare(b_title);
    //       });
    //     } else {
    //       filteredResults.sort((a: any, b: any) => {
    //         let a_title =
    //           a?.data?.[captureBoomGPTComposition]?.title ||
    //           a?.name;
    //         let b_title =
    //           b?.data?.[captureBoomGPTComposition]?.title ||
    //           b?.name;

    //         return b_title.localeCompare(a_title);
    //       });
    //     }
    //     break;
    //   }
    //   case 'note': {
    //     if (sortByAsc) {
    //       filteredResults.sort((a: any, b: any) => {
    //         return a?.data?.[captureBoomGPTComposition]?.note.localeCompare(
    //           b?.data?.[captureBoomGPTComposition]?.note,
    //         );
    //       });
    //     } else {
    //       filteredResults.sort((a: any, b: any) => {
    //         return b?.data?.[captureBoomGPTComposition]?.note.localeCompare(
    //           a?.data?.[captureBoomGPTComposition]?.note,
    //         );
    //       });
    //     }
    //     break;
    //   }
    //   default: {
    //   }
    // }

    filteredResults = result.filter(
      (x: any) =>
        (x?.data_type === '1folder' && captureFilter?.folder) ||
        ((x?.data_type == 'extension_youchat' ||
          x?.data_type == 'extension_poechat' ||
          x?.data_type == 'extension_googlebard' ||
          x?.data_type == 'extension_chatgpt') &&
          captureFilter?.aichat) ||
        (x?.data_type == 'extension_whatsapp_chat' &&
          captureFilter?.human_chat) ||
        ((x?.data_type == 'screenshot' ||
          x?.data_type == 'traced_url' ||
          x?.data_type == 'plain_text' ||
          x?.data_type == 'visual_text' ||
          x?.data_type == 'url' ||
          x?.data_type == 'only_images' ||
          x?.data_type == 'all_but_css' ||
          x?.data_type == 'full_page' ||
          x?.data_type == 'youtube_notes' ||
          x?.data_type == 'all') &&
          captureFilter?.captures) ||
        ((x?.data_type == 'Document' || x?.data_type == 'Image') &&
          captureFilter?.document_image) ||
        ((x?.data_type == 'extension_linkedin' ||
          x?.data_type == 'extension_whatsapp' ||
          x?.data_type == 'extension_indeed' ||
          x?.data_type == 'extension_maps' ||
          x?.data_type == 'web_crm' ||
          x?.data_type == 'extension_monster') &&
          captureFilter?.crm) ||
        x?.data_type === 'item_amazon' ||
        x?.data_type === 'item_ebay' ||
        x?.data_type === 'item_costco' ||
        x?.data_type === 'item_kayak' ||
        x?.data_type === 'item_expedia' ||
        x?.data_type === 'item_agoda'||
        x?.data_type === 'documentation'
    );

    switch (captureSortBy) {
      case 'type':
        {
          if (sortByAsc) {
            filteredResults.sort((a: any, b: any) => {
              const a_type = a?.data_type || a?.data_type;
              const b_type = b?.data_type || b?.data_type;

              return a_type?.localeCompare(b_type);
            });
          } else {
            filteredResults.sort((a: any, b: any) => {
              const a_type = a?.data_type || a?.data_type;
              const b_type = b?.data_type || b?.data_type;

              return b_type.localeCompare(a_type);
            });
          }
        }
        break;
      case 'title': {
        // if (sortByAsc) {
        //   filteredResults.sort((a: any, b: any) => {
        //     let a_title =
        //       a?.data[captureBoomGPTComposition]?.title ||
        //       a?.data[chatGPTCategoryComposition]?.category_name;
        //     let b_title =
        //       b?.data[captureBoomGPTComposition]?.title ||
        //       b?.data[chatGPTCategoryComposition]?.category_name;

        //     return a_title.localeCompare(b_title);
        //   });
        // } else {
        //   filteredResults.sort((a: any, b: any) => {
        //     let a_title =
        //       a?.data[captureBoomGPTComposition]?.title ||
        //       a?.data[chatGPTCategoryComposition]?.category_name;
        //     let b_title =
        //       b?.data[captureBoomGPTComposition]?.title ||
        //       b?.data[chatGPTCategoryComposition]?.category_name;

        //     return b_title.localeCompare(a_title);
        //   });
        // }
        if (sortByAsc) {
          filteredResults.sort((a: any, b: any) => {
            const a_title = a?.title || a?.name;
            const b_title = b?.title || b?.name;

            return a_title.localeCompare(b_title);
          });
        } else {
          filteredResults.sort((a: any, b: any) => {
            const a_title = a?.title || a?.name;
            const b_title = b?.title || b?.name;

            return b_title.localeCompare(a_title);
          });
        }
        break;
      }
      case 'note': {
        if (sortByAsc) {
          filteredResults.sort((a: any, b: any) => {
            return a?.note.localeCompare(b?.note);
          });
        } else {
          filteredResults.sort((a: any, b: any) => {
            return b?.note.localeCompare(a?.note);
          });
        }
        break;
      }
      // default: {
      // }
    }
    return filteredResults;
  });

export const selectArrayFilteredCaptures = (
  category_array: string[],
  search_keyword: string,
  composition_name: string,
  filter_by_doctype?: string
) =>
  createSelector(selectCaptures, (captures: any) => {
    if (typeof captures == 'undefined') {
      return null;
    }
    const current_category_list = [...category_array];

    // Remove Captures that are not created by current user.
    const fromLocalStorage: any = localStorage.getItem('profile');
    const profile = JSON.parse(fromLocalStorage);
    const current_user_document_list = captures.filter((_: any) => {
      return _[composition_name]?.user_id == profile.theUserId;
    });

    // Filter by foldername
    const res = current_user_document_list?.filter((_: any) => {
      const category = _?.[composition_name]?.data?.boom_folder;

      if (typeof category?.data !== 'undefined') {
        const categoryData = category?.data;
        return current_category_list.includes(
          categoryData?.category_name?.toLowerCase()
        );
      }
      return false;
    });

    let result: any;
    if (search_keyword) {
      result = res?.filter((x: any) => {
        let question = x?.[composition_name]?.data?.question;
        const filename = x?.[composition_name]?.data?.filename;
        const title = x?.[composition_name]?.data?.title;
        if (typeof question == 'object') {
          question = question?.data;
          return question.some(
            (q: any) =>
              q?.toLowerCase().indexOf(search_keyword?.toLowerCase()) >= 0
          );
        }

        return (
          question?.toLowerCase().indexOf(search_keyword?.toLowerCase()) >= 0 ||
          filename?.toLowerCase().indexOf(search_keyword?.toLowerCase()) >= 0 ||
          title?.toLowerCase().indexOf(search_keyword?.toLowerCase()) >= 0
        );
      });
    } else {
      result = [...res];
    }

    //if Filter by doctype is available
    if (filter_by_doctype) {
      result = result.filter((_: any) => {
        if (filter_by_doctype == 'extension_chatgpt') {
          return (
            _[composition_name]?.data?.data_type == filter_by_doctype ||
            typeof _[composition_name]?.data?.data_type == 'undefined'
          );
        }
        return _[composition_name]?.data?.data_type == filter_by_doctype;
      });
    }

    result.sort((a: any, b: any) => {
      return (
        b?.data[captureBoomGPTComposition].created_at -
        a?.data[captureBoomGPTComposition].created_at
      );
    });

    return result;
  });

export const selectSharedCaptures = () =>
  createSelector(selectCaptures, (captures: any) => {
    if (typeof captures == 'undefined') {
      return null;
    }

    const result = [...captures.data_array];

    // result.sort((a: any, b: any) => {
    //   // return (
    //   //   b?.data[captureBoomGPTComposition].created_at -
    //   //   a?.data[captureBoomGPTComposition].created_at
    //   // );
    //   return b?.created_at - a?.created_at;
    // });

    return result;
  });

export const selectSearchByUser = (searchKeyword: string) =>
  createSelector(selectCaptures, (captures: any) => {
    if (typeof captures == 'undefined') {
      return null;
    }

    const query_results = captures.query_results;

    const searchedDataWithKeyword = query_results.map((item: any) => {
      let searchedContent: any = '';
      let descTextContent: any = '';
      let descTextContentLowerCase: any = '';
      if (
        item?.data_type === 'extension_chatgpt' ||
        item?.data_type === 'extension_googlebard' ||
        item?.data_type === 'extension_poechat' ||
        item?.data_type === 'extension_youchat'
      ) {
        const desc = item?.answer;
        const htmlEl = document.createElement('div');
        htmlEl.innerHTML = desc;

        descTextContent = htmlEl?.textContent;
        descTextContentLowerCase = htmlEl?.textContent?.toLowerCase();
      } else if (
        item?.data_type === 'item_amazon' ||
        item?.data_type === 'item_ebay' ||
        item?.data_type === 'item_costco' ||
        item?.data_type === 'item_kayak' ||
        item?.data_type === 'item_expedia' ||
        item?.data_type === 'item_agoda'
      ) {
        descTextContent = `${item?.itemData?.description} ${item?.itemData?.delivery}`;
        descTextContentLowerCase = descTextContent?.toLowerCase();
      } else if (
        item?.data_type === 'extension_indeed' ||
        item?.data_type === 'extension_linkedin' ||
        item?.data_type === 'extension_monster' ||
        item?.data_type === 'extension_maps' ||
        item?.data_type === 'web_crm' ||
        item?.data_type === 'extension_whatsapp' ||
        item?.data_type === 'extension_whatsapp_chat'
      ) {
        descTextContent = `${item?.crm_data?.name} ${
          item?.crm_data?.addresses?.[0]?.location || ''
        } ${item?.crm_data?.companyWebsite || ''}  ${
          item?.crm_data?.ceo || ''
        } ${item?.crm_data?.founded || ''} ${
          item?.crm_data?.companySize || ''
        } ${item?.crm_data?.companyRevenue || ''} ${item?.crm_data
          ?.about} || ''`;
        descTextContentLowerCase = descTextContent?.toLowerCase();
      }

      // Find the position of the keyword in the string
      const keywordPosition = descTextContentLowerCase?.indexOf(
        searchKeyword.toLowerCase()
      );

      if (keywordPosition !== -1) {
        // Calculate the start and end positions for the substring
        const start = Math.max(0, keywordPosition - 20); // Ensure we don't go below 0
        const end = Math.min(
          descTextContentLowerCase?.length,
          keywordPosition + searchKeyword?.length + 20
        ); // Ensure we don't go beyond the string length

        // Extract the substring
        const result: string = descTextContent?.substring(start, end);
        searchedContent = result;
      } else {
        searchedContent = '';
      }
      return { ...item, searchedContent };
    });
    return searchedDataWithKeyword;
  });
