import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotFoundComponent } from './modules/pages/not-found/not-found.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TokenInterceptor } from './shared/interceptor/token.interceptor';
import { appReducer } from './shared/store/app.reducer';
import { SuggestionBoxComponent } from './shared/suggestion-box/suggestion-box.component';

import { SharedModule } from './modules/shared/shared.module';

import { CommonModule, NgOptimizedImage } from '@angular/common';

import { ViewCopiedLinkComponent } from './modules/captures/view-copied-link/view-copied-link.component';
import { LoginModalComponent } from './modules/captures/view-copied-link/login-modal/login-modal.component';
import { HomeComponent } from './pages/home/home.component';
import { ShareAccessTokenComponent } from './pages/partials/share-access-token/share-access-token.component';
import { HeroSliderComponent } from './pages/partials/hero-slider/hero-slider.component';
import { DeleteBoardComponent } from './modules/projects/partials/delete-board/delete-board.component';
import { AichatGraphicsWithIconsComponent } from './pages/partials/aichat-graphics-with-icons/aichat-graphics-with-icons.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatButtonModule } from '@angular/material/button';
import { UTCToLocalTimePipe } from './pipes/utcto-local-time.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    SuggestionBoxComponent,

    ViewCopiedLinkComponent,
    LoginModalComponent,
    ShareAccessTokenComponent,
    HeroSliderComponent,
    DeleteBoardComponent,
    AichatGraphicsWithIconsComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    MatButtonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    UTCToLocalTimePipe,
    SharedModule,
    NgOptimizedImage,
    StoreModule.forRoot({ appState: appReducer }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    NgxSkeletonLoaderModule.forRoot({
      animation: 'pulse',
      theme: {
        background: '#b6bed3',
      },
    }),
  ],
  providers: [
    // NgbActiveModal,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  exports: [AichatGraphicsWithIconsComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
