import { Component, HostListener } from '@angular/core';
import { captureBoomGPTComposition } from 'src/app/shared/constants/composition.const';
import { selectCaptureById, selectCaptures } from '../store/captures.selector';
import { Store, select } from '@ngrx/store';
import { captureTypes } from 'src/app/shared/data/enums';
import { Subscription, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemService } from 'src/app/services/item.service';
import { Appstate } from 'src/app/shared/interfaces/appstate';
import { invokeCategoriesAPI } from '../store/categories.action';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { whatsappChatLinker } from 'src/app/shared/constants/linkers.const';

@Component({
  selector: 'app-view-copied-link',
  templateUrl: './view-copied-link.component.html',
  styleUrls: ['./view-copied-link.component.scss'],
})
export class ViewCopiedLinkComponent {
  private subs!: Subscription;

  baseUrl = environment.imageBaseUrl;
  active!: number;
  selectedContact: any;
  items: any;
  composition: string = captureBoomGPTComposition;
  allCaptures$ = this._store.pipe(select(selectCaptures));
  dataLoaded: boolean = false;
  commentId!: string;
  compositionId!: string;
  currentCaptureId: any;
  dismissedLoginModal: boolean = false;
  image: any;
  whatsappMessages: any;
  whatsappCurrentCount: number = 0;
  whatsappTotalCount: number = 0;
  comments!: any;
  username!: string;
  dataType = captureTypes;
  isLoggedIn: boolean = false;
  crmContactInfo: any;

  constructor(
    private _authService: AuthService,
    private route: ActivatedRoute,
    private _items: ItemService,
    private _store: Store,
    private _appStore: Store<Appstate>,
    private _router: Router,
    private modalService: NgbModal,
    // private _location: Location
  ) {
    // let composition = this.route.snapshot.params['composition'];
    // if (composition == 'boomgpt') this.composition = 'boomGPT';
  }
  formatTextWithLineBreaksAndBoldFirst(text: any, keyword: any) {
    if (text) {
      const index = text.indexOf(keyword);
      if (index !== -1) {
        const secondIndex = text.indexOf(keyword, index + keyword.length);
        let formattedText =
          text.slice(0, index) +
          '<strong>' +
          text.slice(index, index + keyword.length) +
          '</strong>';
        if (secondIndex !== -1) {
          formattedText += text.slice(index + keyword.length, secondIndex);
        } else {
          formattedText += text.slice(index + keyword.length);
        }
        formattedText += text
          .slice(secondIndex + keyword.length)
          .replace(/\n\s*\n/g, '<br>')
          .replace(/\n/g, ' ');
        return formattedText;
      }
    }
    return '';
  }
  ngOnInit() {
    // this.getDataForHtml(data);
    // this.getCrmData(this.active)
    if (this._authService.isLoggedIn()) {
      this.isLoggedIn = true;
    } else {
      this.openLoginModal();
    }

    this.route.queryParams.subscribe((params: any) => {
      this.username = params['name'];
      this.image = params['profile']
    });

    let fetchData$ = this.route.paramMap.pipe(
      switchMap((params) => {
        this.currentCaptureId = Number(params.get('id'));
        return this._store.pipe(
          select(selectCaptureById(this.currentCaptureId, this.composition)),
        );
      }),
    );

    this._store.dispatch(invokeCategoriesAPI({ compositionId: '' }));

    this.subs = fetchData$.subscribe((data) => {
      if (data) {
        this.dataLoaded = true;
        let x = { ...data };

        this.items = x?.data[this.composition];
        console.log(this.items);

        this.comments = this.items?.comment?.data;
        this.commentId = this.items?.comment?.id;
        this.compositionId = x?.[this.composition]?.id;

        const userEntity = x?.the_user?.entity;
        if (userEntity) {
          const firstName = userEntity.first_name;
          const lastName = userEntity.last_name;
          console.log('First Name:', firstName);
          console.log('Last Name:', lastName);
        } else {
          console.error('Unable to retrieve user data.');
        }
      } else {
        if (data == null) {
          this.getById();
        }
      }
    });
  }

  getById() {
    this._items.getByIdClean(this.currentCaptureId).subscribe({
      next: (_: any) => {
        console.log('--------', _)
        this.dataLoaded = true;
        this.items = _?.data[this.composition];
        this.getDataForHtml(_?.data)
        this.compositionId = _?.[this.composition]?.id;
      },
      error: (err: any) => {
        this.dataLoaded = true;
        console.log(err);
      },
    });
  }

  goBack() {
    this._router.navigate(['/captures/boomgpt'], {
      queryParams: { s: this.items?.boom_folder?.category_id },
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  openLoginModal() {
    this.dataLoaded = true;
    this.modalService.open(LoginModalComponent, { size: 'md' });
  }
  // changeTab(id: any) {
  //   this.active = id;
  //   this.getCrmData(this.active);
  // }
  // getCrmData(id: any) {
  //   const hasCrmData = this.items?.some(
  //     (item: any) => item?.data?.boomgpt?.crm_data,
  //   );
  //   if (hasCrmData && this.active == id) {
  //     this._items.getUserProfile(id).subscribe((data: any) => {
  //       this.getDataForHtml(data);
  //      console.log("asdfghj",this.getDataForHtml)

  //       this.selectedContact = data;
  //     });
  //     this.whatsappTotalCount = 0;
  //     this.whatsappCurrentCount = 0;
  //     this._items
  //       .getLink(id, whatsappChatLinker + '_s')
  //       .subscribe((data: any) => {
  //         this.whatsappTotalCount = data?.count;
  //         this.whatsappCurrentCount = data?.output?.length;
  //         let uniqueMessage = [
  //           ...new Map(
  //             data?.output.map((item: any) => [item?.id, item]),
  //           ).values(),
  //         ];
  //         this.whatsappMessages = uniqueMessage?.sort((a: any, b: any) => {
  //           let orderA = a.data?.boomgpt?.created_at;
  //           let orderB = b.data?.boomgpt?.created_at;

  //           if (orderA && !orderB) return -1;
  //           if (orderB && !orderA) return 1;

  //           return (
  //             Number(a?.data?.boomgpt?.created_at ?? 0) -
  //             Number(b?.data?.boomgpt?.created_at ?? 0)
  //           );
  //         });
  //       });
  //   }
  // }
  getDataForHtml(data: any) {
    let contactData = data?.boomgpt?.crm_data;
    console.log("asdfghj", contactData)
    let companyData = data?.boomgpt?.crm_data.companyInformation;

    // ! FOR DATA INSIDE COMPANYiNFORMATION
    let compWebsite = companyData?.website;
    let sector = companyData?.sector;
    let location = companyData?.location;
    let companyName = companyData?.name;
    let companyPhone = companyData?.phone?.data?.map((x: any) => ({
      id: x.id,
      phone: x?.data?.phone
    }));
    let companyEmails = companyData?.email?.data?.map((x: any) => ({
      id: x.id,
      email: x?.data?.email
    }));

    let contactPersonData = companyData?.contactPerson;

    // ! FOR CONTACT INFORMATION
    let tag = contactData?.tag;
    let link = contactData?.link;
    let email = contactData?.email;
    let name = contactData?.name;
    let avatar = contactData?.avatar;
    let addresses = contactData?.addresses?.map((x: any) => ({
      id: x.id,
      location: x?.location
    }));

    let phone = contactData?.phone?.map((x: any) => ({
      id: x.id,
      number: x?.number
    }));

    let notes = contactData?.notes?.map((x: any) => ({
      id: x.id,
      title: x?.title,
      details: x?.details
    }));

    let notesId = contactData?.notes?.id

    let about = contactData?.about;
    let founded = contactData?.founded;
    let companySize = contactData?.companySize;
    let companyWebsite = contactData?.companyWebsite;
    let industry = contactData?.industry;
    let workWellbeing = contactData?.workWellbeing;
    let ceo = contactData?.ceo;
    let companyRevenue = contactData?.companyRevenue;
    let website = contactData?.website;
    let ratingAndReviews = contactData?.ratingAndReviews;
    let latitude = contactData?.latitude;
    let longitude = contactData?.longitude;
    let url = contactData?.url;
    let experience = contactData?.experience;
    let education = contactData?.education;
    let isCompany = contactData?.isCompany;
    let contactLinks = contactData?.contactLinks;

    let crmContactInfo = {
      company: {
        id: companyData?.id,
        name: companyName,
        website: compWebsite,
        sector: sector,
        location: location,
        phone: companyPhone,
        emails: companyEmails,
        contactPersons: contactPersonData
      },
      contact: {
        id: data?.boomgpt?.crm_data?.id,
        tag: tag,
        link: link,
        email: email,
        name: name,
        avatar: avatar,
        addresses: addresses,
        phones: phone,
        notes: notes,
        about: about,
        founded: founded,
        companySize: companySize,
        companyWebsite: companyWebsite,
        industry: industry,
        workWellbeing: workWellbeing,
        ceo: ceo,
        companyRevenue: companyRevenue,
        website: website,
        ratingAndReviews: ratingAndReviews,
        latitude: latitude,
        longitude: longitude,
        url: url,
        experience: experience,
        education: education,
        notesId: notesId,
        isCompany: isCompany,
        contactLinks: contactLinks
      }

    };


    removeUndefined(crmContactInfo);

    function removeUndefined(obj: any) {
      for (let prop in obj) {
        if (obj[prop] === undefined) {
          delete obj[prop];
        } else if (typeof obj[prop] === 'object') {
          removeUndefined(obj[prop]);
        }
      }
    }

    return this.crmContactInfo = crmContactInfo;

  }



}
